import React from "react";
import {useFela} from "react-fela";
import {useSelector, useDispatch} from "react-redux";
import {setFormStep} from "redux/actions/setFormStage";
import {setPropertyPartsRating} from "redux/actions/setCalculatorData";
import {propertyConditionTooltips} from "utils/property.constant"
import {sendMetrics} from "../../../utils/google-analytics";

import {Stepper} from "../Stepper";
import {Rating} from "./Rating";
import imgStepOne from "../../../assets/images/dist/rechner/sixth-step-part-1.png";
import imgStepTwo from "../../../assets/images/dist/rechner/sixth-step-part-2.png";
import imgStepThree from "../../../assets/images/dist/rechner/sixth-step-part-3.png";
import imgStepFour from "../../../assets/images/dist/rechner/sixth-step-part-4.png";
import imgStepFive from "../../../assets/images/dist/rechner/sixth-step-part-5.png";
import imgStepSix from "../../../assets/images/dist/rechner/sixth-step-part-6.png";
import imgStepSeven from "../../../assets/images/dist/rechner/sixth-step-part-7.png";
import imgStepEight from "../../../assets/images/dist/rechner/sixth-step-part-8.png";
import imgStepNine from "../../../assets/images/dist/rechner/sixth-step-part-9.png";

import {
    ratingStepContainer,
    ratingStepImage,
} from "./styles";
import {
    firstStepForm,
    formHeading,
    formButton,
    dnone,
    dblock,
} from "../styles";
import {tooltipMobileOnly} from "../FourthStep/styles";

interface Iprops {
    hidden: boolean;
}

interface IRating {
    [key: string]: number
}

export const SixthStep = React.memo((props: Iprops) => {
    const {css} = useFela();
    const dispatch = useDispatch();

    const calculatorData = useSelector(
        (calculatorDataObj) => calculatorDataObj.calculatorReducer
    );

    const propertyRating: IRating = {
        AustAusenwaende: calculatorData && calculatorData.AustAusenwaende ? calculatorData.AustAusenwaende : 0,
        AustDach: calculatorData && calculatorData.AustDach ? calculatorData.AustDach : 0,
        AustFenster: calculatorData && calculatorData.AustFenster ? calculatorData.AustFenster : 0,
        AustInnen: calculatorData && calculatorData.AustInnen ? calculatorData.AustInnen : 0,
        AustDecken: calculatorData && calculatorData.AustDecken ? calculatorData.AustDecken : 0,
        AustBoden: calculatorData && calculatorData.AustBoden ? calculatorData.AustBoden : 0,
        AustSanit: calculatorData && calculatorData.AustSanit ? calculatorData.AustSanit : 0,
        AustHeizung: calculatorData && calculatorData.AustHeizung ? calculatorData.AustHeizung : 0,
        AustSonstiges: calculatorData && calculatorData.AustSonstiges ? calculatorData.AustSonstiges : 0
    };

    const steps = [
        {
            id: 0,
            heading: 'Außenwände',
            name: "AustAusenwaende",
            img: imgStepOne,
            rating: 0,
            content: propertyConditionTooltips.ausenwaende.content
        },
        {
            id: 1,
            heading: 'Dach',
            name: "AustDach",
            img: imgStepTwo,
            rating: 0,
            content: propertyConditionTooltips.dach.content
        },
        {
            id: 2,
            heading: 'Fenster und Außentüren',
            name: "AustFenster",
            img: imgStepThree,
            rating: 0,
            content: propertyConditionTooltips.fenster.content
        },
        {
            id: 3,
            heading: 'Innenwände und Türen',
            name: "AustInnen",
            img: imgStepFour,
            rating: 0,
            content: propertyConditionTooltips.innen.content
        },
        {
            id: 4,
            heading: 'Deckenkonstruktion oder Treppen',
            name: "AustDecken",
            img: imgStepFive,
            rating: 0,
            content: propertyConditionTooltips.decken.content
        },
        {
            id: 5,
            heading: 'Fußböden',
            name: "AustBoden",
            img: imgStepSix,
            rating: 0,
            content: propertyConditionTooltips.boden.content
        },
        {
            id: 6,
            heading: 'Sanitäreinrichtungen',
            name: "AustSanit",
            img: imgStepSeven,
            rating: 0,
            content: propertyConditionTooltips.sanit.content
        },
        {
            id: 7,
            heading: 'Heizung',
            name: "AustHeizung",
            img: imgStepEight,
            rating: 0,
            content: propertyConditionTooltips.heizung.content
        },
        {
            id: 8,
            heading: 'Sonstige technische Ausstattung',
            name: "AustSonstiges",
            img: imgStepNine,
            rating: 0,
            content: propertyConditionTooltips.sonstiges.content
        },
    ];

//  const { activeStep } = useSelector(
//    (formStageObj) => formStageObj.formStageReducer
//  );
    // TODO: Check if this can be safely removed.

    const [currentStep, setCurrentStep] = React.useState<number>(0);
    const [isAllFilled, setIsAllFilled] = React.useState<boolean>(false);

    const handleRatingSubmit = (rating: number) => {
        const propertyName = steps[currentStep].name;
        steps[currentStep].rating = rating;
        propertyRating[propertyName] = rating;

        dispatch(setPropertyPartsRating(propertyRating));
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        } else if (currentStep === steps.length - 1) {
            setIsAllFilled(true);
        }

        return;
    };

    const scrollToForm = () => {
        let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
        window.scrollTo(0, scroll_to);
    };

    const handleBackBtn = () => {
        sendMetrics("BackToFifthStep", "MortageForm", "BackButtonPressed");
        setCurrentStep(0);
        dispatch(setFormStep(5));
        scrollToForm();
    };

    const handleContinueBtn = () => {
        sendMetrics("SixthStepPassed", "MortageForm", "NextButtonPressed");
        setCurrentStep(0);
        setIsAllFilled(false);
        dispatch(setFormStep(7));
        scrollToForm();
    };

//    Mobile tooltip. Currently in development.
//    <div className={css(tooltipMobileOnly)}
//        dangerouslySetInnerHTML={{__html: `<p>${steps[currentStep].content}</p>`}}>
//    </div>

    return (
        <form
            className={css(firstStepForm, props.hidden ? dnone : dblock)}
            id='formHeading'
        >
            <div className='container'>
                <Stepper/>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className={`h2 ${css(formHeading)}`}>{steps[currentStep].heading}</h2>
                    </div>
                    <div className='col-lg-11'>
                        <div className={css(ratingStepContainer)}>
                            <div className={`${css(ratingStepImage)}`}>
                                <img src={steps[currentStep].img} alt="House part illustration"/>
                            </div>
                            <Rating
                                tooltipsContent={steps[currentStep].content}
                                buildingPartId={currentStep}
                                numOfParts={steps.length}
                                onSubmit={handleRatingSubmit}/>
                        </div>
                        <div className={css(formButton)}>
                            <button
                                type='button'
                                className='button empty'
                                onClick={handleBackBtn}
                            >
                                Zurück
                            </button>
                            <button
                                type='button'
                                className='button'
                                disabled={!isAllFilled}
                                onClick={handleContinueBtn}
                            >
                                <span>Weiter</span>
                                <span className="gt">&gt;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
});
