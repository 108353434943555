import axios from "axios";

interface IRequestData {
  Haustyp: string;
  HaustupGenau: string;
  Wohnflaeche: number | null;
  Grundstuecksgroesse: number | null;
  Baujahr: number | null;
  Bauweise: string | null;
  PLZ: string | null;
  Ort: string | null;
  Bundesland: string | null;
  IndikativerBodenrichtswert: number | null;
  Keller: boolean;
  Stockwerk: string;
  Dach: string;
  AustAusenwaende: number | null;
  AustDach: number | null;
  AustFenster: number | null;
  AustInnen: number | null;
  AustDecken: number | null;
  AustBoden: number | null;
  AustSanit: number | null;
  AustHeizung: number | null;
  AustSonstiges: number | null;
  Anrede: string | null;
  Name: string | null;
  Surname: string | null;
  EMail: string | null;
  Telefon: string | null;
  Strasse: string | null;
  Hausnummer: string | null;
  Promocode: string | null;
	Firma?: string | null;
	AdresseRechnung: string | null;
	OrtRechnung: string | null;
	LandRechnung: string | null;
	PLZRechnung: string | null;
};

const getEndpoint = (code: string | null) => {
  return code ? 'promocode' : 'stripe';
};

const submitForm = async (requestData: IRequestData): Promise<{
		purchaseNumber?: string,
		clientSecret?: string,
		Message?: string
	}> => {

  const headers = {
    "Content-Type": "application/json",
  };
  const data = requestData;
  const url = `https://api.eigenheiminfo.de/api_central/v1/pay/${getEndpoint(requestData.Promocode)}`;
  return axios({
    method: "POST",
    url,
    data,
    headers,
  })
  .then((response) => {
		return response.data;
  })
  .catch(({ response }) => {
		return response.data;
  });
};

export default submitForm;
