import { FelaStyle } from "react-fela";

export const stepper: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  "@media (max-width: 768px)": {
    position: "relative",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    "> .dismiss-btn": {
      position: "absolute",
      top: "-4em",
      right: "0",
    },
  },
  "@media (max-width: 992px)": {
    "> span.step": {
      width: "3em",
      minHeight: "3em",
      marginBottom: ".5em",
    }
  },
  "> span.step": {
    display: "inline-flex",
    flexBasis: "20%",
    position: "relative",
    cursor: "default",
    ":after": {
      content: "''",
      width: "50%",
      height: ".35em",
      display: "inline-block",
      position: "absolute",
      right: "0",
      top: "50%",
      backgroundColor: "#fff",
      "@media (max-width: 1399px)": {
        width: "45%",
      },
      "@media (max-width: 1199px)": {
        width: "35%",
      },
      "@media (max-width: 992px)": {
        display: "none"
      }
    },
    ":last-of-type:after": {
      content: "",
      display: "none"
    },
    "> .step-circle": {
      width: "3.5em",
      minHeight: "3.5em",
      border: ".35em solid #fff",
      borderRadius: "50%",
      textAlign: "center",
      "> .step-text": {
        fontSize: "36px",
        fontWeight: "600",
        color: "#007772",
        position: "relative",
        top: "20%",
      },
    },
  },
  "> span.step.step-current > span.step-circle": {
    backgroundColor: "#fff",
  },
  "> span.step.step-past": {
    cursor: "pointer",
    ":after": {
      backgroundColor: "#007772",
    },
    "> .step-circle": {
      backgroundColor: "#007772",
      borderColor: "#007772",
      "> .step-text": {
        color: "#e3ecdc",
      },
    },
  },
  "> .dismiss-btn": {
    border: "none",
    background: "transparent",
  },
});

export const firstStepForm: FelaStyle<{}> = () => ({
  backgroundColor: "#e3ecdc",
  padding: "120px 0px",
  marginBottom: "80px",
  "@media (max-width: 576px)": {
    padding: "120px 0px 30px 0",
  }
});

export const secondStepForm: FelaStyle<{}> = () => ({
  "& .customSelect__control, & .customSelect__menu": {
    marginLeft: "2em"
  }
});

export const formHeading: FelaStyle<{}> = () => ({
  textAlign: "left",
  marginTop: "2em",
  marginBottom: "10px",
  fontSize: '36px',

  "@media (max-width: 768px)": {
    fontSize: "26px",
  },

  "@media (max-width: 576px)": {
    fontSize: "18px",
  },
});

export const formLabel: FelaStyle<{}> = () => ({
  width: "100%",
  marginBottom: "30px",
  "> .DatePicker": {
    width: "100%",
  },
});

export const formLabelText: FelaStyle<{}> = () => ({
  fontSize: "14px",
  fontWeight: "500",
	margin: "1em 0 0 0",
	color: "#007772",
  display: "block",
});

export const formInput: FelaStyle<{}> = () => ({
  width: "100%",
  display: "block",
  border: "none",
  borderBottom: "1px solid #bebebe",
  padding: "10px",
  fontSize: "16px",
  "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: "0",
  },
  "[type=number]": {
    "-moz-appearance": "textfield",
  },
});

export const radioLabel: FelaStyle<{}> = () => ({
  minHeight: "100%",
  padding: "3em",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
  borderRadius: "50px",
  "> img": {
    maxHeight: "15em",
    maxWidth: "13em",
  },
  "@media (max-width: 768px)": {
    padding: "2em 3em",
    "> img": {
      maxHeight: "10em",
      maxWidth: "8em",
    }
  },
  "@media (max-width: 576px)": {
    borderRadius: "20px",
    "> img": {
      maxHeight: "7em",
      maxWidth: "5em",
    },
    "> span": {
      fontSize: "11px"
    },
  },
  "> span": {
    fontSize: "18px",
    lineHeight: "1.75em",
    marginTop: "1.25em",
    fontWeight: "600",
    color: "#007772",
  },
  "&.error": {
    backgroundColor: "#fff7f7",
    border: "2px solid #DF6969"
  }
});

export const formButton: FelaStyle<{}> = () => ({
  marginTop: "5em",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
  "@media (max-width: 576px)": {
    justifyContent: "center",
    "> .button": {
      fontSize: "13px",
      height: "fit-content",
      "> .gt": {
        marginTop: 'unset',
        fontSize: "1.25em",
				position: "unset",
      }
    }
  },
  "> .button": {
    minWidth: "10em",
    fontSize: "14px",
    lineHeight: "1.5em",
    fontWeight: "600",
    padding: "12px 30px !important",
    borderRadius: "12px",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    color: "#007772",
    "&:disabled": {
      color: "#0077728c",
    },
    "> .gt": {
      fontSize: "1.5em",
      minHeight: "unset",
      minWidth: "unset",
      marginTop: 'unset',
      paddingTop: ".1em",
			position: "unset",
      border: "none",
    },
  },
  "> .empty": {
    backgroundColor: "transparent !important",
    color: "#307671",
    border: "1px solid #307671",
    marginRight: "10px",
    justifyContent: "center",
    "@media (max-width: 576px)": {
      marginBottom: "10px",
    },
  },
});

export const formErrorAlert: FelaStyle<{}> = () => ({
  backgroundColor: "#f1fbf0",
  color: "#007772",
  padding: "12px 30px",
  position: "fixed",
  bottom: "30px",
  left: "50%",
  zIndex: "-1",
  maxWidth: "700px",
  transform: "translateX(-50%)",
  borderRadius: "6px",
  marginTop: "10px",
  textAlign: "center",
  opacity: "0",
  transition: "all .25s ease-in",
  width: "100%",
  boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
  "> img": {
    maxWidth: "20px",
    marginRight: "5px",
  },
  "&.active": {
    opacity: "1",
    bottom: "50px",
    zIndex: "100",
  },
  "@media (max-width: 992px)": {
    maxWidth: "500px",
  },
  "@media (max-width: 768px)": {
    maxWidth: "380px !important",
  },
  "@media (max-width: 576px)": {
    maxWidth: "280px !important",
  },
});

export const formErrorModal: FelaStyle<{}> = () => ({
  backgroundColor: "#f1fbf0",
  color: "#007772",
  position: "fixed",
  bottom: "30px",
  left: "50%",
  zIndex: "-1",
	wordBreak: "break-word",
  width: "376px",
  transform: "translateX(-50%)",
  borderRadius: "0",
  marginTop: "10px",
  textAlign: "center",
  opacity: "0",
  transition: "all .25s ease-in",
  boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "space-between",
  padding: "1em 2em",
  "> img": {
    maxWidth: "20px",
    marginRight: "5px",
  },
  "> p": {
    fontSize: "14px",
    lineHeight: "1.5em",
    fontWeight: "400",
    color: "#007772",
    textAlign: "center"
  },
  "> button": {
    fontSize: "24px",
    paddingTop: ".5em",
    lineHeight: "1.5em",
    fontWeight: "700",
    color: "#007772",
    background: "none",
    border: "none",
  },
  "&.active": {
    opacity: "1",
    bottom: "50px",
    zIndex: "100",
  },
  "@media (max-width: 576px)": {
    width: "280px !important",
    padding: "1em .25em",
    "> p": {
      fontSize: "13px",
    }
  },
});

export const formErrorAlertNoFixed: FelaStyle<{}> = () => ({
  position: 'static',
  left: '0',
  display: 'block',
  marginBottom: '30px',
  transform: 'translateX(0)',
  marginLeft: 'auto',
  marginRight: 'auto'
});

export const buttonBack: FelaStyle<{}> = () => ({
  marginRight: "30px",
  backgroundColor: "transparent !important",
  border: "1px solid #779eed",
  color: "#779eed",
  ":hover": {
    color: "#779eed",
  },
});

export const radioField: FelaStyle<{}> = () => ({
  display: "flex",
});

export const radioInput: FelaStyle<{}> = () => ({
  marginRight: "20px",
  marginBottom: "30px",
  "> input": {
    display: "none",
    ":checked + label": {
      borderColor: "#779eed",
    },
  },
  "> label": {
    fontSize: "16px",
    padding: "10px",
    textAlign: "center",
    borderRadius: "5px",
    border: "1px solid #bebebe",
    minWidth: "65px",
    cursor: "pointer",
  },
});

export const formSubHeading: FelaStyle<{}> = () => ({
  textAlign: "center",
  fontSize: "24px",
  marginBottom: "50px",
  marginTop: "20px",
  fontWeight: "500",
  color: "#307671",
});

export const formDescription: FelaStyle<{}> = () => ({
  color: "#343347",
  fontSize: "18px",
  fontWeight: "500",
  marginBottom: "10px",
});

export const finalMortageNumber: FelaStyle<{}> = () => ({
  color: "#307671",
  fontSize: "30px",
  fontWeight: 500,
  display: "block",
  textAlign: "center",
  marginBottom: "50px",
});

export const fieldsHeading: FelaStyle<{}> = () => ({
  fontSize: "18px",
  color: "#343347",
  marginBottom: "10px",
  fontWeight: "500",
  textAlign: "center",
});

export const formStepInfo: FelaStyle<{}> = () => ({
  textAlign: "center",
  marginBottom: "50px",
  color: "#307671",
  fontSize: "22px",
  "> span": {
    fontWeight: "600",
  },

  "@media (max-width: 576px)": {
    fontSize: "18px",
    marginBottom: "25px",
  },
});

export const customInput: FelaStyle<{}> = () => ({
  fontSize: "14px",
  marginTop: "1.5em",
  minWidth: "18em",
  minHeight: "3.25em",
  padding: "0 .75em",
  border: "1px solid #97bbb8",
  borderRadius: "8px !important",
  color: "#007772",
  "&.has-error": {
    backgroundColor: "#fff6f6",
    border: "1px solid #df6969",
  },
});

export const customSelect: FelaStyle<{}> = () => ({
  fontSize: "14px",
  marginTop: "1.5em !important",
});


export const customDatepicker: FelaStyle<{}> = () => ({
  fontSize: "14px",
  "> .react-date-picker__wrapper": {
    width: "100%",
    fontSize: "14px",
    lineHeight: "21px",
    padding: "0 0 0 1em",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "inherit",
    "> .react-date-picker__inputGroup > input": {
      color: "#007772",
    },
    "> .react-date-picker__button": {
      height: "100%",
      width: "20%",
      fontSize: "2em",
      marginTop: "-.15em",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      color: "#007772",
    },
  },
});

export const filledSelect: FelaStyle<{}> = () => ({
  "> .customSelect__control > .customSelect__indicators": {
    paddingRight: "2em !important"
  }
});

export const infoBubble: FelaStyle<{}> = () => ({
  width: "19px",
  height: "19px",
  borderRadius: "50%",
  backgroundColor: "#e3ecdc",
  position: "absolute",
  bottom: "1em",
  right: "1em",
  fontSize: "13px",
  lineHeight: "normal",
  fontWeight: "500",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 767px)": {
    display: "none"
  }
});

export const infoBubbleSelect: FelaStyle<{}> = () => ({
  width: "19px",
  height: "19px",
  borderRadius: "50%",
  backgroundColor: "#e3ecdc",
  position: "absolute",
  bottom: "1em",
  right: "2em",
  fontSize: "13px",
  lineHeight: "normal",
  fontWeight: "500",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const propertyDetailsCardsContainer: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "space-around",
});

export const propertyDetailsCard: FelaStyle<{}> = () => ({
  marginTop: "3.5em",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
  flexBasis: "35%",
  "@media (max-width: 523px)": {
    alignItems: "flex-start",
    flexBasis: "50%"
  },
  "> img": {
    width: "fit-content",
		height: "6em",
  },
  "> div": {
    position: "relative",
    "> div": {
      marginTop: "1em",
      padding: "0",
      "> .customSelect__control": {
        width: "18em"
      },
      "> .customSelect__menu": {
        width: "18em"
      },
    },
  },
  "> div > div > .tooltip-content": {
    maxWidth: "34em",
		padding: "1em",
    fontSize: "11px",
    lineHeight: "1.25em",
    display: "flex",
    flexFlow: "column nowrap",
    color: "#007772",

		"> div": {
			paddingBottom: "1em"
		},

		"> img": {
			width: "100%"
		}
  }
});

export const locationPriceContainer: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "space-between",
});

export const rangeInputContainer: FelaStyle<{}> = () => ({
  width: "100%",
  flexBasis: "65%",
  "@media screen and (max-width: 576px)": {
    "> div > .track-background": {
      width: "90% !important",
    }
  },
  "@media screen and (max-width: 767px)": {
    "> div > .track-background": {
      width: "83% !important",
      "&:before": {
        width: "114%",
        left: "-1.5em",
      },
    }
  },
  "@media screen and (max-width: 410px)": {
    "> div > .track-background": {
      "&:before": {
        width: "120%",
        left: "-1.5em",
      },
    }
  },
  "> div > .track-background": {
    width: "80% !important",
    marginLeft: "1em",
    "&:before": {
      content: "''",
      width: "120%",
      height: "5px",
      display: "flex",
      position: "relative",
      left: "-1.75em",
      backgroundColor: "#97bbb8 !important",
      borderRadius: "4px",
    },
    "> div": {
      position: "relative",
      top: "-.25em",
    },
  },
  "> span.range-input-text": {
    marginLeft: "-.75em",
    fontSize: "14px",
    lineHeight: "1.5em",
    fontWeight: "400",
    color: "#007772",
  },
});

export const rangeInputLabels: FelaStyle<{}> = () => ({
  width: "90%",
  fontSize: "12px",
  fontWeight: "400",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  color: "#007772",
  "@media screen and (max-width: 576px)": {
    width: "unset",
  },
  "@media screen and (max-width: 767px)": {
    marginTop: "2em",
  },
});

export const rangeNumbers: FelaStyle<{}> = () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "18px",
  marginBottom: "20px",
  marginRight: "-4%",

  "> span": {
    padding: "0",
    textAlign: "center",
    // flexBasis: "calc(100%/11)",
    flexBasis: "8%",    

    "@media (max-width: 1024px)": {
      fontSize: "16px",
    },
  },

  "> span:first-child": {
    flexBasis: "4%",
    textAlign: "left",
  },


  "@media (max-width: 768px)": {
    display: "none",
  },
});

export const dnone: FelaStyle<{}> = () => ({
  display: "none",
});

export const dblock: FelaStyle<{}> = () => ({
  display: "block",
});
