import React from "react";
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import {useFela} from "react-fela";
import {useSelector, useDispatch} from "react-redux";
import {setFormStep} from "redux/actions/setFormStage";
import {setMainPropertyData} from "redux/actions/setCalculatorData";
import {types} from "../../../utils/property.constant";
import {sendMetrics} from "../../../utils/google-analytics";

import {Stepper} from "../Stepper";
import selectTooltipImage from "../../../assets/images/dist/rechner/third-step-select-tooltip-image.jpg";
import wohnflacheIcon from "../../../assets/images/dist/rechner/third-step-wohnflache.png";
import grundstucksgrobeIcon from "../../../assets/images/dist/rechner/third-step-grundstucksgrobe.png";
import baujahrIcon from "../../../assets/images/dist/rechner/third-step-baujahr.png";
import bauweiseIcon from "../../../assets/images/dist/rechner/third-step-bauweise.png";

import {
    selectContainer,
    input,
    customSelect,
    cardRow
} from "./styles";

import {
    firstStepForm,
    formHeading,
    formButton,
    formLabelText,
    propertyDetailsCardsContainer,
    propertyDetailsCard,
    customInput,
    customDatepicker,
    infoBubbleSelect,
    dnone,
    dblock,
} from "../styles";

interface Iprops {
    hidden: boolean;
}

interface FormData {
    wohnflaeche: number | string;
    grundstuecksgroesse: number | string;
    baujahr: number | null;
    bauweise: string;
}

interface FormValidation {
    wohnflaeche: boolean;
    grundstuecksgroesse: boolean;
    baujahr: boolean;
    bauweise: boolean;
}

export const ThirdStep = React.memo((props: Iprops) => {
    const {css} = useFela();
    const dispatch = useDispatch();

    const selectOptions = [
        {value: 0, label: 'Massiv'},
        {value: 1, label: 'Fertighaus'}
    ];
    const initialFormValidation = {
        wohnflaeche: false,
        grundstuecksgroesse: false,
        baujahr: false,
        bauweise: false,
    };

//  const { activeStep } = useSelector(
//    (formStageObj) => formStageObj.formStageReducer
//  );
    // TODO: Check if this can be safely removed.

    const propertyType = useSelector(
        (propertyTypeObj) => propertyTypeObj.calculatorReducer.propertyTypeId
    );

    const [baujahr, setBaujahr] = React.useState(new Date());
    const [formData, setFormData] = React.useState<FormData>({
        wohnflaeche: '',
        grundstuecksgroesse: '',
        baujahr: new Date().getFullYear(),
        bauweise: '',
    });
    const [formValidation, setFormValidation] = React.useState<FormValidation>(initialFormValidation);

    const validateInputs = () => {
        let hasErrors = false;
        const validation = {...formValidation};
        Object.keys(formValidation).forEach((fieldName, i) => {
            switch (fieldName) {
                case 'bauweise':
                    if (propertyType !== types[1].id || formData[fieldName]?.length) {
                        return;
                    }
                    validation[fieldName] = true;
                    hasErrors = true;
                    break;
                default:
                    if (!formData[fieldName] || (formData[fieldName] && formData[fieldName].length < 1)) {
                        validation[fieldName] = true;
                        hasErrors = true;
                    }
            }
        });

        setFormValidation(validation);
        return hasErrors;
    };

    const clearValidation = () => {
        setFormValidation(initialFormValidation);
    };

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearValidation();
        const value = parseFloat(e.target.value);
        setFormData({
            ...formData,
            [e.target.id]: (isNaN(value) || value < 0) ? '' : value
        });
    }

    const handleBaujahrInput = (date: Date) => {
        setBaujahr(date);
        setFormData({
            ...formData,
            baujahr: date ? date.getFullYear() : null
        });
    };

    const handleBauweiseInput = (e: { value: number, label: string }) => {
        clearValidation();
        setFormData({
            ...formData,
            bauweise: e.label
        });
    };

    const updateStoreData = () => {
        const filledForm = {
            wohnflaeche: formData.wohnflaeche ? formData.wohnflaeche : null,
            grundstuecksgroesse: formData.grundstuecksgroesse ? formData.grundstuecksgroesse : null,
            baujahr: formData.baujahr ? formData.baujahr : null,
            bauweise: formData.bauweise ? formData.bauweise : selectOptions[0].label,
        };
        dispatch(setMainPropertyData(filledForm));
    };

    const scrollToForm = () => {
        let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
        window.scrollTo(0, scroll_to);
    };

    const handleBackBtn = () => {
        sendMetrics("BackToSecondStep", "MortageForm", "BackButtonPressed");
        dispatch(setFormStep(2));
        scrollToForm();
    };

    const handleContinueBtn = async () => {
        const hasErrors = validateInputs();
        if (hasErrors) return;
        sendMetrics("ThirdStepPassed", "MortageForm", "NextButtonPressed");
        dispatch(setFormStep(4));
        updateStoreData();
        scrollToForm();
    };

    const showTooltip = () => {
        return (
            <div className="tooltip-content">
                <div>
                    Sie finden den Grundstücksgröße im Grundbuch Ihrer Immobilie (siehe Beispiel)
                </div>
                <img
                    src={selectTooltipImage}
                    alt="Example of input"
                />
            </div>
        )
    };

    return (
        <form
            className={css(firstStepForm, props.hidden ? dnone : dblock)}
            id='formHeading'
        >
            <div className='container'>
                <Stepper/>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className={`h2 ${css(formHeading)}`}>Eckdaten der Immobilie</h2>
                    </div>
                    <div className='col-xl-10 offset-lg-1 col-lg-11'>
                        <div className={css(propertyDetailsCardsContainer, !propertyType ? cardRow : {})}>
                            <div className={css(propertyDetailsCard)}>
                                <img src={wohnflacheIcon} alt="Wohnflaeche icon"/>
                                <span className={css(formLabelText)}>
									Wohnfläche (in m2)
								</span>
                                <input
                                    name='wohnflaeche'
                                    type='number'
                                    min={0}
                                    id='wohnflaeche'
                                    value={formData.wohnflaeche}
                                    className={`${css(customInput, input)} ${formValidation.wohnflaeche ? 'has-error' : ''}`}
                                    onChange={handleInput}
                                />
                            </div>
                            <div className={css(propertyDetailsCard)}>
                                <img src={grundstucksgrobeIcon} alt="Grundstuecksgroesse icon"/>
                                <span className={css(formLabelText)}>
									Grundstücksgröße (in m2)
								</span>
                                <div>
                                    <input
                                        name='grundstuecksgroesse'
                                        type='number'
                                        min={0}
                                        id='grundstuecksgroesse'
                                        value={formData.grundstuecksgroesse}
                                        className={`${css(customInput, input)} ${formValidation.grundstuecksgroesse ? 'has-error' : ''}`}
                                        onChange={handleInput}
                                    />
                                    <span
                                        data-tip="React-tooltip"
                                        data-for="infoBubbleSelect"
                                        className={css(infoBubbleSelect)}>i</span>
                                    <ReactTooltip
                                        place="right"
                                        type="light"
                                        effect="solid"
                                        id="infoBubbleSelect"
                                        clickable
                                        data-delay-hide='500'
                                        getContent={() => showTooltip()}/>
                                </div>
                            </div>
                            <div className={css(propertyDetailsCard)}>
                                <img src={baujahrIcon} alt="Baujahr icon"/>
                                <span className={css(formLabelText)}>
									Baujahr
								</span>
                                <div>
                                    <DatePicker
                                        className={`${css(customInput, input, customSelect, customDatepicker)} ${formValidation.baujahr ? 'has-error' : ''}`}
                                        name='baujahr'
                                        id='baujahr'
                                        value={baujahr}
                                        format="y"
                                        minDate={new Date(1001, 0)}
                                        maxDate={new Date()}
                                        maxDetail="decade"
                                        clearIcon={null}
                                        calendarIcon={`\u032C`}
                                        openCalendarOnFocus={false}
                                        onChange={handleBaujahrInput}
                                    />
                                </div>
                            </div>
                            <div
                                className={css(propertyType === types[1].id ? propertyDetailsCard : {display: "none"})}>
                                {
                                    propertyType === types[1].id ?
                                        <>
                                            <img src={bauweiseIcon} alt="Bauweise icon"/>
                                            <span className={css(formLabelText)}>
											Bauweise
										</span>
                                            <div className={css(selectContainer)}>
                                                <Select
                                                    classNamePrefix='customSelect'
                                                    options={selectOptions}
                                                    name='bauweise'
                                                    id='bauweise'
                                                    placeholder=""
                                                    className={`${css(customSelect, input)} ${formValidation.bauweise ? 'has-error' : ''}`}
                                                    onChange={handleBauweiseInput}/>
                                            </div>
                                        </> : ""
                                }
                            </div>
                        </div>
                        <div className={css(formButton)}>
                            <button
                                type='button'
                                className='button empty'
                                onClick={handleBackBtn}
                            >
                                Zurück
                            </button>
                            <button
                                type='button'
                                className='button'
                                onClick={handleContinueBtn}
                            >
                                <span>Weiter</span>
                                <span className="gt">&gt;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
});
