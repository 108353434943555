import React from "react";
import { useFela } from "react-fela";
import { useSelector, useDispatch } from "react-redux";
import {sendMetrics} from "../../../utils/google-analytics";

import { IdoubleRadio } from "../../../interfaces";
import { setFormStep, clearFormStages } from "redux/actions/setFormStage";
import { setPropertyType, setPropertyTypeId } from "../../../redux/actions/setCalculatorData";
import { types } from "../../../utils/property.constant";

import { Stepper } from "../Stepper";
import eigentumswohnungIcon from "../../../assets/images/dist/rechner/first-step-eigentumswohnung.svg";
import hausIcon from "../../../assets/images/dist/rechner/first-step-haus.svg";

import { radioButtonGroup} from "./styles";
import {
  firstStepForm,
  formHeading,
  radioLabel,
  formButton,
  dnone,
  dblock,
} from "../styles";

interface Iprops {
  hidden: boolean;
}

export const FirstStep = React.memo((props: Iprops) => {
  const { css } = useFela();
  const dispatch = useDispatch();

  const { activeStep, initFormData } = useSelector(
    (formStageObj) => formStageObj.formStageReducer
  );

	const initialType = {
    active: null,
  };

  const [isError, setIsError] = React.useState<boolean>(false);

  const [type, setType] = React.useState<IdoubleRadio>(initialType);

  const handleType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setType({
      active: parseInt(e.target.value),
    });
    setIsError(false);
  };

  const handleContinueBtn = () => {
    if (type.active === null) {
      setIsError(true);
      return;
    }
		dispatch(clearFormStages(false));

    const i = types.findIndex(el => el.id === type.active);
    dispatch(setPropertyType(types[i].name));
    dispatch(setPropertyTypeId(type.active));
    sendMetrics("FirstStepPassed", "MortageForm", "NextButtonPressed");

    dispatch(setFormStep(2));
    let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
    window.scrollTo(0, scroll_to);
  };

	React.useEffect(() => {
		if (initFormData) {
			setType(initialType);
		}
	}, [initFormData]);

	React.useEffect(() => {
		if (activeStep === 1) {
			dispatch(clearFormStages(true));
		}
	}, [activeStep]);

  return (
    <form
      className={css(firstStepForm, props.hidden ? dnone : dblock)}
      id='formHeading'
    >
      <div className='container'>
        <Stepper />
        <div className='row'>
          <div className='col-12'>
            <h2 className={`h2 ${css(formHeading)}`}>Art der Immobilie</h2>
          </div>
          <div className='offset-xl-2 col-xl-7 offset-lg-1 col-lg-9'>
            <div className={css(radioButtonGroup)}>
              <div className='col-6 col-md-5 offset-md-1'>
                <input type="radio"
                       id="first-step-eigentumswohnung"
                       name="type"
                       value={types[0].id}
                       checked={type.active === types[0].id}
                       onChange={handleType}>
                </input>
                <label htmlFor="first-step-eigentumswohnung"
                       className={`${css(radioLabel)} ${isError ? "error" : ""}`}>
                  <img src={eigentumswohnungIcon} alt={`${types[0].name} option`} />
                  <span>{types[0].name}</span>
                </label>
              </div>
              <div className='col-6 col-md-5'>
                <input type="radio"
                       id="first-step-haus"
                       name="type"
                       value={types[1].id}
                       checked={type.active === types[1].id}
                       onChange={handleType}>
                </input>
                <label htmlFor="first-step-haus"
                       className={`${css(radioLabel)} ${isError ? "error" : ""}`}>
                  <img src={hausIcon} alt={`${types[1].name} option`} />
                  <span>{types[1].name}</span>
                </label>
              </div>
            </div>
            <div className={css(formButton)}>
              <button
                type='button'
                className='button'
                onClick={handleContinueBtn}
              >
                <span>Weiter</span>
                <span className="gt">&gt;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});
