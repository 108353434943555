import React from "react";
import Select from 'react-select';
import { useFela } from "react-fela";
import { useSelector, useDispatch } from "react-redux";
import { setFormStep } from "redux/actions/setFormStage";
import { setPropertyExterior } from "redux/actions/setCalculatorData";
import { types } from "../../../utils/property.constant";
import {sendMetrics} from "../../../utils/google-analytics";

import { Stepper } from "../Stepper";
import splittedHouse from "../../../assets/images/dist/rechner/fifth-step-splitted-house.png";
import splittedHouseFilled from "../../../assets/images/dist/rechner/fifth-step-splitted-house-filled.png";
import tallHouse from "../../../assets/images/dist/rechner/fifth-step-tall-house.png";

import {
  houseDetailsContainer,
  partSelectionContainer,
  rightSection,
  customSelect,
} from "./styles";
import {
  firstStepForm,
  formHeading,
  formButton,
  dnone,
  dblock,
} from "../styles";

interface Iprops {
  hidden: boolean;
}

interface ISelectOptions {
  value: number;
  label: string;
  code?: string;
}

interface FormValidation {
  keller: boolean,
  dach: boolean,
  stockwerk: boolean,
}

export const FifthStep = React.memo((props: Iprops) => {
  const { css } = useFela();
  const dispatch = useDispatch();

  const kellerOptions = [
    { value: 1, label: 'vorhanden' },
    { value: 0, label: 'nicht vorhanden' }
  ];
  const dachOptions = [
    { value: 0, label: 'ausgebaut' },
    { value: 1, label: 'nicht ausgebaut' },
    { value: 2, label: 'Flachdach' }
  ];
  const stockwerkOptions = [
    {
      value: 0,
      label: 'Erdgeschoss',
      code: 'EG'
    },
    {
      value: 1,
      label: 'Erdgeschoss und Obergeschoss',
      code: 'EG+OG'
    }
  ];
  const initialFormValidation = {
    keller: false,
    dach: false,
    stockwerk: false,
  };

//  const { activeStep } = useSelector(
//    (formStageObj) => formStageObj.formStageReducer
//  );
    // TODO: Check if this can be safely removed.
  const propertyType = useSelector(
    (propertyTypeObj) => propertyTypeObj.calculatorReducer.propertyTypeId
  );

  const [isRoofSelected, setRoofSelection] = React.useState<boolean>(false);
  const [dach, setDach] = React.useState<ISelectOptions | null>(null);
  const [keller, setKeller] = React.useState<ISelectOptions | null>(null);
  const [stockwerk, setStockwerk] = React.useState<ISelectOptions | null>(null);
  const [formValidation, setFormValidation] = React.useState<FormValidation>(initialFormValidation);

  const handleRoofClick = () => {
    if (propertyType !== types[1].id) return;
    setRoofSelection(!isRoofSelected);
  };

  const handleDachSelect = (obj: ISelectOptions | null) => {
    setDach(obj);
    clearValidation();
  };

  const handleKellerSelect = (obj: ISelectOptions | null) => {
    setKeller(obj);
    clearValidation();
  };

  const handleStockwerkSelect = (obj: ISelectOptions | null) => {
    setStockwerk(obj);
    clearValidation();
  };

  const validateInputs = () => {
    let hasErrors = false;
    const validation = { ...formValidation };
    if (!keller) {
      validation.keller = true;
      hasErrors = true;
    }
    if (propertyType === types[1].id && isRoofSelected) {
      if (!dach) {
        validation.dach = true;
        hasErrors = true;
      }
      if (!stockwerk) {
        validation.stockwerk = true;
        hasErrors = true;
      }
    }

    setFormValidation(validation);
    return hasErrors;
  };

  const clearValidation = () => {
    setFormValidation(initialFormValidation);
  };

  const scrollToForm = () => {
    let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
    window.scrollTo(0, scroll_to);
  };

  const handleBackBtn = () => {
      sendMetrics("BackToFourthStep", "MortageForm", "BackButtonPressed");
    dispatch(setFormStep(4));
    scrollToForm();
  };

  const handleContinueBtn = () => {
    let hasErrors = validateInputs();
    if (hasErrors) return;
    const exteriorData = {
      dach: dach ? dach.label : dachOptions[1].label,
      keller: keller ? !!keller.value : null,
      stockwerk: stockwerk ? stockwerk.code : ''
    };
    sendMetrics("FifthStepPassed", "MortageForm", "NextButtonPressed");
    dispatch(setPropertyExterior(exteriorData));
    dispatch(setFormStep(6));
    scrollToForm();
  };

  React.useEffect(() => {
    if (propertyType !== types[1].id) return;
    setRoofSelection(true);
  }, [propertyType]);

  return (
    <form
      className={css(firstStepForm, props.hidden ? dnone : dblock)}
      id='formHeading'
    >
      <div className='container'>
        <Stepper />
        <div className='row'>
          <div className='col-12'>
            <h2 className={`h2 ${css(formHeading)}`}>Eckdaten der Immobilie</h2>
          </div>
          <div className='col-xl-10 offset-lg-1 col-lg-11'>
            <div className={css(houseDetailsContainer)}>
              <div className={`${css(partSelectionContainer)} ${isRoofSelected ? 'selected' : ''}`}>
                <button
                  className="image-wrapper"
                  type="button"
                  disabled={propertyType === types[0].id}
                  onClick={handleRoofClick}>
                  {
                    isRoofSelected ? 
                    <img src={splittedHouseFilled} alt="House parts" /> : 
                    <img src={splittedHouse} alt="House parts" />
                  }
                </button>
                <div className="select-wrapper">
                  {
                    isRoofSelected ? 
                    <Select
                      classNamePrefix='customSelect'
                      options={dachOptions}
                      placeholder="Dach"
                      className={`${css(customSelect)} ${formValidation.dach ? 'has-error' : ''} dach`}
                      onChange={handleDachSelect} /> : ""
                  }
                  <Select
                    classNamePrefix='customSelect'
                    options={propertyType !== types[1].id ? [kellerOptions[0]] : kellerOptions}
                    placeholder="Keller"
                    className={`${css(customSelect)} ${formValidation.keller ? 'has-error' : ''}`}
                    onChange={handleKellerSelect} />
                </div>
              </div>
              {
                isRoofSelected ? 
                <div className={css(rightSection)}>
                  <img src={tallHouse} alt="House parts" />
                  <Select
                    classNamePrefix='customSelect'
                    options={stockwerkOptions}
                    placeholder="Stockwerk"
                    className={`${css(customSelect)} ${formValidation.stockwerk ? 'has-error' : ''}`}
                    onChange={handleStockwerkSelect} />
                </div> : ""
              }
            </div>
            <div className={css(formButton)}>
              <button
                type='button'
                className='button empty'
                onClick={handleBackBtn}
              >
                Zurück
              </button>
              <button
                type='button'
                className='button'
                onClick={handleContinueBtn}
              >
                <span>Weiter</span>
                <span className="gt">&gt;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});
