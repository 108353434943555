export const setPropertyType = (payload: string) => ({
  type: 'SET_PROPERTY_TYPE',
  payload,
});

export const setPropertyTypeId = (payload: number) => ({
  type: 'SET_PROPERTY_TYPE_ID',
  payload,
});

export const setPropertySubType = (payload: string) => ({
  type: 'SET_PROPERTY_SUBTYPE',
  payload,
});

export const setPropertyFloor = (payload: string) => ({
  type: 'SET_PROPERTY_FLOOR',
  payload,
});

export const setMainPropertyData = (payload: {}) => ({
  type: 'SET_MAIN_PROPERTY_DATA',
  payload,
});

export const setPropertyLocation = (payload: {}) => ({
  type: 'SET_PROPERTY_LOCATION',
  payload,
});

export const setGroundValue = (payload: number) => ({
  type: 'SET_GROUND_VALUE',
  payload,
});

export const setPropertyExterior = (payload: {}) => ({
  type: 'SET_PROPERTY_EXTERIOR',
  payload,
});

export const setPropertyPartsRating = (payload: {}) => ({
  type: 'SET_PROPERTY_PARTS_RATING',
  payload,
});

export const setModernizations = (payload: {}) => ({
  type: 'SET_MODERNIZATIONS',
  payload,
});

export const setAdditionalInfo = (payload: {}) => ({
  type: 'SET_ADDITIONAL_INFO',
  payload,
});

export const setUsageInfo = (payload: {}) => ({
  type: 'SET_USAGE_INFO',
  payload,
});

export const setCalculatorData = (payload: {}) => ({
  type: 'SET_FULL_DATA',
  payload,
});
