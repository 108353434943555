import { FelaStyle } from "react-fela";

export const optionRow: FelaStyle<{}> = () => ({
  marginTop: "1em",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  borderRadius: "15px",
  ":hover": {
    backgroundColor: "#ffffff4d"
  },
  "@media screen and (max-width: 991px)": {
    flexFlow: "column nowrap"
  },
});

export const option: FelaStyle<{}> = () => ({
  padding: "0 0 0 .5em",
  display: "flex",
  flexBasis: "50%",
  alignItems: "center",
});

export const optionLabel: FelaStyle<{}> = () => ({
  fontSize: "14px",
  lineHeight: "1.5em",
  fontWeight: "500",
  color: "#007772",
});

export const radioGroup: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-around",
  flex: "1 0 auto",
  "> div > input": {
    ":checked + label": {
      "> .label-text": {
        color: "#007772",
      },
      "> .label-dot": {
        border: "3px solid #fff",
        backgroundColor: "#007772",
        cursor: "default"
      },
    },
  }
});

export const radioButton: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "center",
});

export const radioRatingLabel: FelaStyle<{}> = () => ({
  fontSize: "12px",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "5em",
  "@media screen and (max-width: 576px)": {
    fontSize: "10px",
  },
  "> .label-text": {
    fontSize: "inherit",
    fontWeight: "600",
    color: "#97bbb8",
    "@media screen and (max-width: 576px)": {
      fontSize: "10px",
    },
    "@media screen and (max-width: 991px)": {
      position: "unset !important",
      display: "inline-block !important",
    },
    "@media screen and (min-width: 991px)": {
      "&.visible": {
        color: "#007772 !important",
      },
    },
    "&.visible": {
      position: "absolute",
      top: "-2em",
    },
    "&.hidden": {
      display: "none",
    },
  },
  "> .label-dot": {
    height: "32px",
    width: "32px",
    border: "none",
    borderRadius: "50%",
    backgroundColor: "#fff",
    cursor: "pointer"
  },
});
