import React from "react";
import { BePartnerForm } from '../components/BePartnerForm';

import partnerSvg from "../assets/images/dist/partner-section-img.svg";

export const Partner: React.FC = () => {
  return (
    <>
      <section className='be-partner-section'>
        <div className='container'>
          <div className='row align-items-start'>
            <div className='col-xl-6 col-lg-7'>
              <h3 className='be-partner__title'>Partner werden</h3>
              <p className='be-partner__descr'>
              Sie als Bauherr, Bauträger oder Makler, sowie Immobilienexperte können von unserer Dienstleitung profitieren. Wir bieten maßgeschneiderte Pakete mit attraktiven Konditionen für Sie an. Je nach Bedarf können individuelle Lösungen gestaltet werden. Wir freuen uns auf die Zusammenarbeit
              </p>
            </div>
            <div className='col-xl-5 offset-xl-1 col-lg-5'>
              <div className='be-partner__img'>
                <img src={partnerSvg} alt='parner werden icon' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <BePartnerForm/>
    </>
  );
};
