import React from "react";
import {useDispatch} from "react-redux";
import {FelaStyle, useFela} from "react-fela";
import {sendMetrics} from "../utils/google-analytics";

import {HeaderPdf} from "../components";
import {MortageForm} from "../components";

import calculatorIcon from "../assets/images/dist/eigenheim_icons_calculator.svg";
import handIcon from "../assets/images/dist/eigenheim_icons_hand.svg";
import fenceIcon from "../assets/images/dist/eigenheim_icons_fence.svg";
import constructionIcon from "../assets/images/dist/eigenheim_icons_construction.svg";
import documentIcon from "../assets/images/dist/eigenheim_icons_document.svg";
import rulerIcon from "../assets/images/dist/eigenheim_icons_ruler.svg";
import workPlan1Icon from "../assets/images/dist/work-plan1.svg";
import workPlan2Icon from "../assets/images/dist/work-plan-2.svg";
import aboutUsSvg from "../assets/images/dist/about-us.svg";
import finalIllustration from "../assets/images/dist/illustration-final.svg";

export const Homepage: React.FC = () => {
//  const dispatch = useDispatch(); // TODO: Check if this can be safely removed.
    const {css} = useFela();
    const handleCalculatorScroll = () => {
        sendMetrics("Going to the calculator", "MortageForm", "RechnerButtonPressed");
        let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
        window.scrollTo(0, scroll_to);
    }

    return (
        <>
            <section className={`homepage-section ${css(homepageSection)}`}>
                <div className='homepage-section__container'>
                    <div className='row'>
                        <div className='homepage-section__col col-12 col-md-8'>
                            <h1 className={`h1 homepage-section__heading ${css(mainHeading)}`}>
                                Berechnen Sie den Wert Ihrer <br/>
                                Immobilie schnell und unkompliziert!
                            </h1>
                            <div className={`homepage-section__body ${css(homepageSectionText)}`}>
                                <p className={`homepage-section__descr ${css(homepageDescr)}`}>
                                    Unsere Berechnungsgrundlage ist auf Basis der Richtlinie zur Ermittlung des
                                    Sachwerts nach NHK 2010 konzipiert
                                </p>
                                <a className='button' onClick={handleCalculatorScroll}>
                                    Zum&nbsp;Rechner
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-4 col-lg-5 col-md-7'>
                            <HeaderPdf/>
                        </div>
                    </div>
                </div>
            </section>
            <section className='calculator-section' id='CalculatorForm'>
                <MortageForm/>
            </section>
            <section className='advantages-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='h2'>
                                Warum mit EigenheimInfo Immobilienwert <br/>
                                berechnen
                            </h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-4 col-md-6'>
                            <div className='advantages-item'>
                                <div className='advantages-item__img'>
                                    <img src={calculatorIcon} alt='calculator hand'/>
                                </div>
                                <p className='advantages-item__descr'>
                                    Sie möchten eine Immobilie kaufen und wissen nicht, ob der Preis nach Angaben des
                                    Verkäufers fair ist. Bei uns können Sie das schnell und zuverlässig prüfen
                                </p>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6'>
                            <div className='advantages-item'>
                                <div className='advantages-item__img'>
                                    <img src={fenceIcon} alt='fence comp'/>
                                </div>
                                <p className='advantages-item__descr'>
                                    Sie haben vor längerer Zeit eine Wohnung gekauft und möchten diese verkaufen und
                                    wissen nicht, welchen Preis Sie ansetzen können. Ermitteln Sie den Wert bei uns
                                </p>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6'>
                            <div className='advantages-item'>
                                <div className='advantages-item__img'>
                                    <img src={constructionIcon} alt='construction icon'/>
                                </div>
                                <p className='advantages-item__descr'>
                                    Sie haben Ihr Haus mit viel Eigenleistung umgebaut und saniert und möchten jetzt den
                                    Gesamtwert ermitteln. Unser Tool hat alle Komponenten, die Sie dazu brauchen
                                </p>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6'>
                            <div className='advantages-item'>
                                <div className='advantages-item__img'>
                                    <img src={documentIcon} alt='document icon'/>
                                </div>
                                <p className='advantages-item__descr'>
                                    Ihr geerbtes Grundstück haben Sie mit einem Fertighaus bebaut. Jetzt möchten Sie
                                    wissen, was die Immobilie mit der entsprechenden Grundstücksgröße kostet. Bei uns
                                    können Sie es ganz einfach berechnen
                                </p>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6'>
                            <div className='advantages-item'>
                                <div className='advantages-item__img'>
                                    <img src={handIcon} alt='hands icon'/>
                                </div>
                                <p className='advantages-item__descr'>
                                    Bei einem Neubau kommt es nicht nur auf die Planungszeit an, sondern auch auf lange
                                    Genehmigungsverfahren.<br/>
                                    Für ein richtungsweisendes Zwischenergebnis können Sie bei uns den Beleihungswert
                                    für die Bank errechnen, falls Sie nachfinanzieren müssen
                                </p>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6'>
                            <div className='advantages-item'>
                                <div className='advantages-item__img'>
                                    <img src={rulerIcon} alt='ruler icon'/>
                                </div>
                                <p className='advantages-item__descr'>
                                    Sie haben in Ihrem bestehenden Ein- oder Zweifamilienhaus das Dachgeschoss saniert
                                    und ausgebaut. Mit unserem System errechnen Sie den dadurch erreichten Mehrwert
                                    Ihrer Immobilie
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='work-plan-section'>
                <div className='container'>
                    <div className='row work-plan-item'>
                        <div className='col-xl-4 col-lg-6'>
                            <div className='work-plan-item__img'>
                                <img src={workPlan1Icon} alt='work plan icon'/>
                            </div>
                        </div>
                        <div className='col-xl-7 offset-xl-1 col-lg-6'>
                            <h4 className='work-plan-item__title'>
                                Wie funktioniert EigenheimInfo
                            </h4>
                            <ul className='work-plan-item__list'>
                                <li>
                                    <div className='work-plan-item__list__num'>1</div>
                                    <h5 className='work-plan-item__list__title'>
                                        Online-Bewertungstool
                                    </h5>
                                    <p className='work-plan-item__list__descr'>
                                        Mit Hilfe unseres Online-Rechners ermitteln Sie den Wert Ihrer Immobilie
                                    </p>
                                </li>
                                <li>
                                    <div className='work-plan-item__list__num'>2</div>
                                    <h5 className='work-plan-item__list__title'>
                                        10 Schritte System
                                    </h5>
                                    <p className='work-plan-item__list__descr'>
                                        Detaillierte Eingabe der Daten zu Ihrem Objekt in unser System
                                    </p>
                                </li>
                                <li>
                                    <div className='work-plan-item__list__num'>3</div>
                                    <h5 className='work-plan-item__list__title'>
                                        Zusammenfassung
                                    </h5>
                                    <p className='work-plan-item__list__descr'>
                                        Kurzbeschreibung der gesammelten Daten mit Verbesserungsmöglichkeiten
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='row work-plan-item align-items-center'>
                        <div className='col-xl-7 offset-xl-1 col-lg-6'>
                            <ul className='work-plan-item__list'>
                                <li>
                                    <div className='work-plan-item__list__num'>4</div>
                                    <h5 className='work-plan-item__list__title'>
                                        Bezahlung
                                    </h5>
                                    <p className='work-plan-item__list__descr'>
                                        Direktüberweisung / Paypal / Kreditkarte / Gutscheincode
                                    </p>
                                </li>
                                <li>
                                    <div className='work-plan-item__list__num'>5</div>
                                    <h5 className='work-plan-item__list__title'>
                                        PDF-Zertifikat
                                    </h5>
                                    <p className='work-plan-item__list__descr'>
                                        Sie bekommen das Zertifikat in wenigen Minuten per E-Mail
                                    </p>
                                </li>
                            </ul>
                            <a
                                className={`button ${css({
                                    "@media (max-width: 578px)": {marginLeft: "10px"},
                                })}`}
                                onClick={handleCalculatorScroll}
                            >
                                Zum&nbsp;Rechner
                            </a>
                        </div>
                        <div className='col-xl-4 col-lg-6'>
                            <div className='work-plan-item__img ml-lg-5 pt-md-0 pt-4'>
                                <img src={workPlan2Icon} alt='work plan icon'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='about-us-section'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-xl-5 col-lg-5 order-last order-lg-first'>
                            <div className='about-us__img'>
                                <img src={aboutUsSvg} alt='about us icon'/>
                            </div>
                        </div>
                        <div className='offset-xl-1 col-lg-6 order-first order-lg-last'>
                            <h2 className='h2'>Was zeichnet unser Bewertungstool aus</h2>
                            <p className='about-us__descr'>
                                Der Wert Ihrer Immobilie von einem Sachverständigen oder Gutachter zu bekommen, ist
                                zeitaufwendig und mit hohen Kosten verbunden. Unsere Berechnungsgrundlage ist auf Basis
                                der Richtlinie zur Ermittlung des Sachwerts nach NHK 2010 konzipiert. D.h. Sie bekommen
                                ein Ergebnis, das fachlich absolut aussagekräftig ist, dies sofort und zu einem
                                unschlagbaren Preis. </p>
                            <break/>
                            <p className='about-us__descr'>
                                Sie werden nicht mit unnötigen Anrufen und Werbung aller Art belästigt.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='footer-illustration-section'>
                <div className='partners-image-big'>
                    <img src={finalIllustration} alt='final illustration'/>
                </div>
            </section>
        </>
    );
};

const homepageSection: FelaStyle<{}> = () => ({
    "@media (min-width: 1920px)": {
        minHeight: '850px'
    },

    backgroundPosition: "top",
    padding: "145px 0 0",

    "@media (max-width: 1400px)": {
        padding: "50px 0 0",
        minHeight: "600px",
    },

    "@media (max-width: 992px)": {
        backgroundPosition: "center right",
        minHeight: "0",
        backgroundSize: "cover",
    },
});

const mainHeading: FelaStyle<{}> = () => ({

    "@media (max-width: 1400px)": {
        fontSize: "38px",
        lineHeight: "1.5",
        marginBottom: "15px !important",
    },

    "@media (max-width: 992px)": {
        fontSize: "32px !important",
        lineHeight: "45px",
    },

    "@media (max-width: 768px)": {
        fontSize: "28px !important",
        lineHeight: "40px",
    },

    "@media (max-width: 576px)": {
        fontSize: "18px !important",
        lineHeight: "1.5",
        marginBottom: "0 !important",
    },
});

const homepageSectionText: FelaStyle<{}> = () => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "1100px",

    "@media (max-width: 1400px)": {
        maxWidth: '800px',
        alignItems: 'flex-start'
    },

    "@media (max-width: 1200px)": {
        display: 'block'
    }
});

const homepageDescr: FelaStyle<{}> = () => ({
    fontSize: "32px",
    "@media (max-width: 1400px)": {
        fontSize: "18px",
        lineHeight: "1.5",
        marginBottom: "15px !important",
    },

    "@media (max-width: 992px)": {
        fontSize: "18px !important",
        lineHeight: "24px",
    },

    "@media (max-width: 768px)": {
        fontSize: "18px !important",
        lineHeight: "24px",
    },

    "@media (max-width: 576px)": {
        fontSize: "14px !important",
        lineHeight: "24px",
    },
});
