import React from "react";
//import { useFela } from "react-fela"; // TODO: Check if this can be safely removed.

import downloadPdf from "../assets/images/dist/download-pdf.png";

export const HeaderPdf = React.memo(() => {
//  const { css } = useFela(); // TODO: Check if this can be safely removed.

  return (
    <>
      <div
        className={`homepage-section__pdf-btn`}
      >
        <a
          href="/files/pdf/eigenheiminfo-wertermittlung-schablone.pdf"
          color="transparent"
          target="_blank">
          <img src={downloadPdf} alt='pdf icon' />
        </a>
        <span className={`homepage-section__pdf-btn-text`}>
          Für nur 19 EUR erhalten Sie eine professionelle Wertermittlung nach den gängigen Bankenrichtlinien und der aktuellsten Marktsituation (siehe Beispiel PDF)
        </span>
      </div>
    </>
  );
});
