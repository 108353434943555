import React from "react";
import { useFela } from "react-fela";
import { radioGroup, radioButton, radioRatingLabel } from "./styles";
import { dnone } from "../styles";

interface Iprops {
  id: number;
  showLabel: boolean;
  onSubmit: (arg0: number, arg1: number) => void;
}

export const RadioGroup = React.memo((props: Iprops) => {
  const { css } = useFela();
  const [currentRating, setCurrentRating] = React.useState<Number>(1);

  const onRatingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rating = parseInt(e.target.value);
    setCurrentRating(rating);
    props.onSubmit(rating, props.id);
  };

  const renderOptions = () => {
    const options: JSX.Element[] = [];
    const labels = ['keine', 'einfach', 'mittel', 'überwiegend', 'umfassend'];
    for (let i = 1; i <= 5; i++) {
      options.push(
        <div className={css(radioButton)} key={i}>
          <input
            type="radio"
            id={`rating-${props.id}-step-${i}`}
            name={`rating-${props.id}`}
            value={i}
            checked={currentRating === i}
            className={css(dnone)}
            onChange={onRatingChange}>
          </input>
          <label
            htmlFor={`rating-${props.id}-step-${i}`}
            className={css(radioRatingLabel)}>
              <span className={`label-text ${props.showLabel ? "visible" : "hidden"}`}>
                {labels[i-1]}
              </span>
            <div className="label-dot"></div>
          </label>
        </div>
      );
    }
    return options;
  }

  return (
    <div className={css(radioGroup)}>
      {renderOptions()}
    </div>
  );
});
