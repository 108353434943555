interface IInitialProps {
  Haustyp: string;
  propertyTypeId: number | null;
  HaustupGenau: string;
  Wohnflaeche: number | null;
  Grundstuecksgroesse: number | null;
  Baujahr: number | null;
  Bauweise: string;
  Stockwerk: string | number;
  Dach: string | null,
  Keller: boolean | null,
  Adresse: string | null;
  PLZ: string | null;
  Ort: string | null;
  Bundesland: string | null;
  IndikativerBodenrichtswert: number | null;
  AustAusenwaende: number | null;
  AustDach: number | null;
  AustFenster: number | null;
  AustInnen: number | null;
  AustDecken: number | null;
  AustBoden: number | null;
  AustSanit: number | null;
  AustHeizung: number | null;
  AustSonstiges: number | null;
  ModDach: number | null,
  ModFenster: number | null,
  ModLeitung: number | null,
  ModHeizung: number | null,
  ModAwande: number | null,
  ModBad: number | null,
  ModInnen: number | null,
  ModGrund: number | null,
  ZusTief: number | null,
  ZusGarage: number | null,
  ZusDopgarage: number | null,
  ZusStell: number | null,
  ZusCarport: number | null,
  ZusTerrasse: string,
  ZusTerrasseFlaeche: number | null,
  ZusPhoto: number | null,
  ZusAus: boolean,
  ZusWbalkon: boolean,
  ZusBalkonFlaeche: number | null,
  Zus2Nutzung: string | null,
  Zus2Vermiet: string | null,
}

interface ICalculatorAction {
  type: string,
  payload: string | number | {}
}

interface IPayloadObject extends ICalculatorAction {
  payload: {
    [key: string]: number
  }
}

const initialState: IInitialProps = {
  Haustyp: '',
  propertyTypeId: null,
  HaustupGenau: '',
  Wohnflaeche: null,
  Grundstuecksgroesse: null,
  Baujahr: null,
  Bauweise: '',
  Stockwerk: '',
  Dach: null,
  Keller: null,
  Adresse: null,
  PLZ: null,
  Ort: null,
  Bundesland: null,
  IndikativerBodenrichtswert: null,
  AustAusenwaende: null,
  AustDach: null,
  AustFenster: null,
  AustInnen: null,
  AustDecken: null,
  AustBoden: null,
  AustSanit: null,
  AustHeizung: null,
  AustSonstiges: null,
  ModDach: null,
  ModFenster: null,
  ModLeitung: null,
  ModHeizung: null,
  ModAwande: null,
  ModBad: null,
  ModInnen: null,
  ModGrund: null,
  ZusTief: 0,
  ZusGarage: 0,
  ZusDopgarage: 0,
  ZusStell: 0,
  ZusCarport: 0,
  ZusTerrasse: '',
  ZusTerrasseFlaeche: 0,
  ZusPhoto: 0,
  ZusAus: false,
  ZusWbalkon: false,
  ZusBalkonFlaeche: 0,
  Zus2Nutzung: '',
  Zus2Vermiet: null,
}

export const calculatorReducer = (state = initialState, action: IPayloadObject) => {
  switch(action.type) {
    case 'SET_PROPERTY_TYPE':
      return {
        ...state,
        Haustyp: action.payload
      }
    case 'SET_PROPERTY_TYPE_ID':
      return {
        ...state,
        propertyTypeId: action.payload
      }
    case 'SET_PROPERTY_SUBTYPE':
      return {
        ...state,
        HaustupGenau: action.payload
      }
    case 'SET_PROPERTY_FLOOR':
      return {
        ...state,
        Stockwerk: action.payload
      }
    case 'SET_MAIN_PROPERTY_DATA':
      return {
        ...state,
        Wohnflaeche: action.payload.wohnflaeche,
        Grundstuecksgroesse: action.payload.grundstuecksgroesse,
        Baujahr: action.payload.baujahr,
        Bauweise: action.payload.bauweise || '',
      }
    case 'SET_PROPERTY_LOCATION':
      return {
        ...state,
        Adresse: action.payload.strase,
        PLZ: action.payload.plz,
        Ort: action.payload.ort,
        Bundesland: action.payload.bundesland
      }
    case 'SET_GROUND_VALUE':
      return {
        ...state,
        IndikativerBodenrichtswert: action.payload
      }
    case 'SET_PROPERTY_EXTERIOR':
      const newState = {
        ...state,
        Keller: action.payload.keller,
        Dach: action.payload.dach || ''
      };

      if (action.payload.stockwerk.toString().length) {
        newState.Stockwerk = action.payload.stockwerk;
      }
      return newState;
    case 'SET_PROPERTY_PARTS_RATING':
      return {
        ...state,
        ...action.payload
      }
    case 'SET_MODERNIZATIONS':
      return {
        ...state,
        ...action.payload
      }
    case 'SET_ADDITIONAL_INFO':
      return {
        ...state,
        ZusTief: Math.floor(action.payload.tiefgaragenstellplatz),
        ZusGarage: Math.floor(action.payload.garage),
        ZusDopgarage: Math.floor(action.payload.doppelgarage),
        ZusStell: Math.floor(action.payload.stellplatz),
        ZusCarport: Math.floor(action.payload.carport),
        ZusTerrasse: action.payload.isTerrace !== null ? action.payload.isTerrace : '',
        ZusTerrasseFlaeche: action.payload.terraceArea !== null ? action.payload.terraceArea : 0,
        ZusPhoto: Math.floor(action.payload.photovoltaikanlage),
        ZusAus: action.payload.ausenanlagen,
        ZusWbalkon: action.payload.isBalcony !== null ? action.payload.isBalcony : false,
        ZusBalkonFlaeche: action.payload.balconyArea !== null ? action.payload.balconyArea : 0,
      }
    case 'SET_USAGE_INFO':
      return {
        ...state,
        Zus2Nutzung: action.payload.usageType,
        Zus2Vermiet: action.payload.usability
      }
    case 'SET_FULL_DATA':
      return {
        ...state,
        ...action.payload
      }

    default:
      return state;
  }
}