import React, { useEffect, useRef } from "react";
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import { useFela } from "react-fela";
import { useSelector, useDispatch } from "react-redux";
import { setFormStep } from "redux/actions/setFormStage";
import { setPropertyLocation, setGroundValue } from "redux/actions/setCalculatorData";
import {sendMetrics} from "../../../utils/google-analytics";
import getBodenwert from "services/getBodenwert.service";
import getPropertyCoodrinates from "services/getPropertyCoordinates.service"
import { Stepper } from "../Stepper";
import { GoogleMap } from "../GoogleMap";

import {
  inputsContainer,
  mapContainer,
  customSelect,
  customInputContainer, tooltipMobileOnly
} from "./styles";
import {
  firstStepForm,
  formHeading,
  formButton,
  locationPriceContainer,
  customInput,
  infoBubble,
  dnone,
  dblock,
} from "../styles";

interface Iprops {
  hidden: boolean;
}

interface FormData {
  strase: string | null,
  plz: string,
  ort: string | null,
  bundesland: string | null,
  manuelleBodenrichtwert: string | number | undefined,
}

interface FormValidation {
  strase: boolean,
  plz: boolean,
  ort: boolean,
  bundesland: boolean,
  bodenrichtwert: boolean,
}

export const FourthStep = React.memo((props: Iprops) => {
  const { css } = useFela();
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);

  const selectOptions = [
    { value: 0, label: 'Baden-Württemberg' },
    { value: 1, label: 'Bayern' },
    { value: 2, label: 'Berlin' },
    { value: 3, label: 'Brandenburg		' },
    { value: 4, label: 'Bremen' },
    { value: 5, label: 'Hamburg' },
    { value: 6, label: 'Hessen' },
    { value: 7, label: 'Mecklenburg-Vorpommern' },
    { value: 8, label: 'Niedersachsen' },
    { value: 9, label: 'Nordrhein Westfalen' },
    { value: 10, label: 'Rheinland-Pfalz' },
    { value: 11, label: 'Saarland' },
    { value: 12, label: 'Sachsen' },
    { value: 13, label: 'Sachsen-Anhalt' },
    { value: 14, label: 'Schleswig-Holstein' },
    { value: 15, label: 'Thüringen' },
  ];

  // let bodenrichtwertFoundOnServer: boolean = false;

  const initialFormValidation = {
    strase: false,
    plz: false,
    ort: false,
    bundesland: false,
    bodenrichtwert: false
  };

//  const { activeStep } = useSelector(
//    (formStageObj) => formStageObj.formStageReducer
//  );
    // TODO: Check if this can be safely removed.

  const [bundesland, setBundesland] = React.useState<{ value: number, label: string } | null>(null);
  const [coordinates, setCoordinates] = React.useState<{ lat: number, lng: number } | null>(null);
  const [formData, setFormData] = React.useState<FormData>({
    strase: null,
    plz: '',
    ort: null,
    bundesland: null,
    manuelleBodenrichtwert: '',
  });
  const [formValidation, setFormValidation] = React.useState<FormValidation>(initialFormValidation);
  const [bodenrichtwertFoundOnServer, setBodenrichtwertFoundOnServer] = React.useState<boolean>(false);

  const handleInput = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = e.target.value;
		let newFormData = {};
    if (e.target.id === 'plz' && value.length > 5) {
			let int = parseInt(e.target.value.slice(0, 5));
      newFormData = {
				[e.target.id]: (isNaN(int) || int < 0) ? '' : int.toString()
			};
    } else if (e.target.id === 'manuelleBodenrichtwert' && parseInt(e.target.value) < 0) {
			value = ''
			e.target.value = ''
    } else {
			newFormData = {
				[e.target.id]: value
			};
		}
    let bodenwertData: {} | undefined = {};
    if ((e.target.id === 'plz' && value.length === 5) ||
        (e.target.id === 'strase' && value.length >= 5)) {
      bodenwertData = await updateBodenwert({ [e.target.id]: value });
      newFormData = {
        ...newFormData,
        ...bodenwertData
      }
    }
    setFormData({
      ...formData,
      ...newFormData
    });
    clearValidation();
  };

  const handleBundeslandInput = async (obj: {value: number, label: string}) => {
    setBundesland(obj);
    const bodenwertData = await updateBodenwert({ bundesland: obj.label });
    setFormData({
      ...formData,
      ...bodenwertData
    });
    clearValidation();
  };

  const updateBodenwert = async (obj: { plz?: string, bundesland?: string, strase?: string }) => {
    let plz = obj.plz ? parseInt(obj.plz) : (formData.plz ? parseInt(formData.plz) : null);
    let bundeslandLabel = obj.bundesland ? obj.bundesland : (bundesland ? bundesland.label : null);
    let strase = obj.strase ? obj.strase : (formData.strase ? formData.strase : null);
    if (!plz || !bundeslandLabel) return;

    const bodenwert = await getBodenwert({
      plz: plz,
      bundesland: bundeslandLabel
    });
    setBodenrichtwertFoundOnServer(bodenwert);
    const bodenwertData = {
      strase,
      bundesland: bundeslandLabel,
    };
    return bodenwertData;
  };

  const getPropertyLocation = () => {
    if (!formData.strase || !formData.plz || formData.plz.length < 5 || !formData.ort || !bundesland || (bundesland && (!bundesland.label || !bundesland.label.length))) return;

    const address = `${formData.strase}, ${formData.plz}, ${formData.ort}, ${bundesland ? bundesland.label : ''}`;
    getPropertyCoodrinates(address, setCoordinates);
  };

  const validateInputs = (bodenrichtwert?: number) => {
    let hasErrors = false;
    const validation = { ...formValidation };

    Object.keys(formValidation).forEach((fieldName) => {
      switch (fieldName) {
        case 'plz':
          if (!formData[fieldName] || formData[fieldName].length !== 5) {
            validation[fieldName] = true;
            hasErrors = true;
            return;
          }
          break;
        case 'bundesland':
          if (!bundesland || bundesland.length < 1) {
            validation[fieldName] = true;
            hasErrors = true;
            return;
          }
          break;
        case 'bodenrichtwert':
          break;
        default:
          if (!formData[fieldName] || (formData[fieldName] && formData[fieldName].length < 1)) {
            validation[fieldName] = true;
            hasErrors = true;
          }
      }
    });

    if (!bodenrichtwert) {
      validation.bodenrichtwert = true;
      hasErrors = true;
    }

    setFormValidation(validation);
    return hasErrors;
  };

  const clearValidation = () => {
    setFormValidation(initialFormValidation);
  };

  const scrollToForm = () => {
    let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
    window.scrollTo(0, scroll_to);
  };

  const handleBackBtn = () => {
      sendMetrics("BackToThirdStep", "MortageForm", "BackButtonPressed");
      dispatch(setFormStep(3));
    scrollToForm();
  };

  const handleContinueBtn = () => {
    const bodenrichtwert = formData.manuelleBodenrichtwert ? parseInt(formData.manuelleBodenrichtwert) : bodenrichtwertFoundOnServer ? -1: undefined;//(formData.indikativerBodenrichtwert ? parseInt(formData.indikativerBodenrichtwert) : undefined);
    let hasErrors = validateInputs(bodenrichtwert);
    if (hasErrors || !bodenrichtwert) return;

    sendMetrics("FourthStepPassed", "MortageForm", "NextButtonPressed");
    dispatch(setPropertyLocation(formData));
    dispatch(setGroundValue(bodenrichtwert));
    dispatch(setFormStep(5));
    scrollToForm();
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getPropertyLocation();
  }, [formData.strase, formData.plz, formData.ort, bundesland]);

  return (
    <form
      className={css(firstStepForm, props.hidden ? dnone : dblock)}
      id='formHeading'
    >
      <div className='container'>
        <Stepper />
        <div className='row'>
          <div className='col-12'>
            <h2 className={`h2 ${css(formHeading)}`}>Lage und Bodenrichtwert der Immobilie</h2>
          </div>
          <div className='col-xl-10 offset-lg-1 col-lg-11'>
            <div className={css(locationPriceContainer)}>
              <div className={css(inputsContainer)}>
                <input
                  name='strase'
                  type='text'
                  id='strase'
                  placeholder="Straße und Hausnummer"
                  className={`${css(customInput)} ${formValidation.strase ? 'has-error' : ''}`}
                  onChange={handleInput}
                />
                <input
                  name='plz'
                  type='number'
									min={0}
                  id='plz'
                  placeholder="PLZ"
									value={formData.plz}
                  className={`${css(customInput)} ${formValidation.plz ? 'has-error' : ''}`}
                  onChange={handleInput}
                />
                <input
                  name='ort'
                  type='text'
                  id='ort'
                  placeholder="Ort"
                  className={`${css(customInput)} ${formValidation.ort ? 'has-error' : ''}`}
                  onChange={handleInput}
                />
                <Select
                  classNamePrefix='customSelect'
                  options={selectOptions}
                  placeholder="Bundesland"
                  name="bundesland"
                  id="bundesland"
                  className={`${css(customSelect)} ${formValidation.bundesland ? 'has-error' : ''}`}
                  onChange={handleBundeslandInput} />
                <div className={css(customInputContainer)}>
                  <input
                    type="text"
                    name='indikativerBodenrichtwert'
                    id='indikativerBodenrichtwert'
                    placeholder="Indikativer Bodenrichtwert (in EUR/m2)"
                    value={`${bodenrichtwertFoundOnServer.valueOf() ? "Der Wert wird aus unserer Datenbank ausgewählt" : "Bodenrichtwert wurde in Datenbank nicht gefunden"}`}//{formData.indikativerBodenrichtwert}
                    disabled
                    className={css(customInput)}
                    onChange={handleInput}
                  />
                  <span
                    data-tip="indikativer-tooltip"
                    data-for="indikativer-tooltip"
                    className={css(infoBubble)}>i</span>
                  <ReactTooltip
                    place="right"
                    type="light"
                    effect="solid"
                    id="indikativer-tooltip"
                    clickable={true}
                    getContent={() => "Bodenrichtwerte können je nach Standort stark abweichen. Sie können den Wert im Feld unten manuell eingeben" }/>
                </div>
                <p className={css(tooltipMobileOnly)}>Bodenrichtwerte können je nach Standort stark abweichen. Sie können den Wert im Feld unten manuell eingeben</p>
                <div className={css(customInputContainer)}>
                  <input
                    type="number"
                    name='manuelleBodenrichtwert'
                    id='manuelleBodenrichtwert'
										min={0}
										value={formData.manuelleBodenrichtwert}
                    placeholder="Manuelle Eingabe, Bodenrichtwert (in EUR/m2)"
                    className={`${css(customInput)} ${formValidation.bodenrichtwert ? 'has-error' : ''}`}
                    onChange={handleInput}
                  />
                  <span
                    data-tip="manuelle-tooltip"
                    data-for="manuelle-tooltip"
                    className={css(infoBubble)}>i</span>
                  <ReactTooltip
                    place="right"
                    type="light"
                    effect="solid"
                    id="manuelle-tooltip"
                    clickable
                    getContent={() => "Falls Sie den genau Bodenrichtwert wissen, bitte hier eingeben" }/>
                </div>
              </div>
              <div className={css(mapContainer)}>
								{
									!props.hidden ?
									<GoogleMap coordinates={coordinates} /> :null
								}
              </div>
            </div>
            <div className={css(formButton)}>
              <button
                type='button'
                className='button empty'
                onClick={handleBackBtn}
              >
                Zurück
              </button>
              <button
                type='button'
                className='button'
                onClick={handleContinueBtn}
              >
                <span>Weiter</span>
                <span className="gt">&gt;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});
