import { FelaStyle } from "react-fela";

export const inputsContainer: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "space-between",
  flexBasis: "40%",
  "@media screen and (max-width: 767px)": {
    flexBasis: "100%",
    order: "2",
  },
  "> input": {
    minHeight: "unset",
    height: "2.5em",
    width: "100%",
  },
  "> .has-error": {
    backgroundColor: "#fff6f6",
    border: "1px solid #df6969",
  },
  "> div > input": {
    minHeight: "unset",
    height: "2.5em",
    width: "100%",
  }
});

export const mapContainer: FelaStyle<{}> = () => ({
  marginTop: "1.5em",
  "@media (max-width: 767px)": {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    order: "1"
  },
  "> #map": {
    width: "370px",
    height: "429px",
    borderRadius: "15px",
    "@media (max-width: 576px)": {
      width: "282px",
      height: "282px",
    },
  },
});

export const customSelect: FelaStyle<{}> = () => ({
  marginTop: "1em",
  "&.has-error": {
    backgroundColor: "#fff6f6",
    border: "1px solid #df6969",
    borderRadius: "8px"
  },
  "> .customSelect__control": {
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%",
    height: "2.5em",
  },
  "> .customSelect__menu": {
    width: "100% !important"
  },
});

export const tooltipMobileOnly: FelaStyle<{}> = () => ({
  display: "none",
  "@media (max-width: 767px)": {
    display: "block",
    maxWidth: "25em",
    fontSize: "12px",
    lineHeight: "1.25em",
    flexFlow: "column nowrap",
    borderRadius: "8px",
    color: "#007772",
    border: "rgb(0, 119, 114) solid 2px",
    paddingBlock: "2px",
    margin: "auto",
    marginTop: "2em",
    background: "white",
    textAlign: "center"
  }
})

export const customInputContainer: FelaStyle<{}> = () => ({
  position: "relative",
  "@media (max-width: 767px)": {
    "> input": {
      minHeight: "3.25em",
    },
    "> span": {
      bottom: ".75em",
    },
  },
  "> input": {
    width: "100%",
    minHeight: "2.5em",
    overflow: "hidden",
    wordBreak: "break-all",
    "&:disabled": {
      backgroundColor: "#fff"
    }
  },
  "> div.__react_component_tooltip": {
    maxWidth: "25em",
    fontSize: "12px",
    lineHeight: "1.25em",
    display: "flex",
    flexFlow: "column nowrap",
    borderRadius: "8px",
    color: "#007772",
  },
  "> span": {
    bottom: ".75em",
    cursor: "default",
  },
});
