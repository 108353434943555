import React from "react";
import Select from 'react-select';
import { useFela } from "react-fela";
import { useSelector, useDispatch } from "react-redux";
import { setFormStep } from "redux/actions/setFormStage";
import { setAdditionalInfo } from "redux/actions/setCalculatorData";
import { types } from "../../../utils/property.constant";
import { sendMetrics } from "../../../utils/google-analytics";

import { Stepper } from "../Stepper";
import { RangeSlider } from "../RangeSlider";
import { Terrace } from "./Terrace";
import solarPanelImg from "../../../assets/images/dist/rechner/eighth-step-solar-panel.png";
import sunImg from "../../../assets/images/dist/rechner/eighth-step-sun.png";

import {
  additionalInfoContainer,
  rightSection,
  inputsContainer,
  customSelect,
  solarPanelContainer,
} from "./styles";
import {
  firstStepForm,
  formHeading,
  formButton,
  customInput,
  dnone,
  dblock,
} from "../styles";

interface Iprops {
  hidden: boolean;
}

interface FormData {
  garage: number | string | undefined,
  doppelgarage: number | string | undefined,
  stellplatz: number | string | undefined,
  tiefgaragenstellplatz: number | string | undefined,
  carport: number | string | undefined,
  ausenanlagen: boolean,
  photovoltaikanlage: number | null,
  isTerrace: boolean | string | null,
  terraceArea: number | null,
  isBalcony: boolean | null,
  balconyArea: number | null,
}

interface FormValidation {
  terrace: boolean,
  balcony: boolean,
  area: boolean,
}

export const EighthStep = React.memo((props: Iprops) => {
  const { css } = useFela();
  const dispatch = useDispatch();

//  const { activeStep } = useSelector(
//    (formStageObj) => formStageObj.formStageReducer
//  );
    // TODO: Check if this can be safely removed.
  const propertyType = useSelector(
    (propertyTypeObj) => propertyTypeObj.calculatorReducer.propertyTypeId
  );

  const selectOptions = [
    { value: 1, label: 'vorhanden' },
    { value: 0, label: 'nicht vorhanden' }
  ];
  const terraceOptions = [
    { value: 0, label: 'vorhanden' },
    { value: 1, label: 'nicht vorhanden' },
    { value: 2, label: 'überdacht' }
  ];
  const balconyOptions = [
    { value: 0, label: 'vorhanden' },
    { value: 1, label: 'nicht vorhanden' }
  ];
  const rangeInputOptions = [
    { value: 0, label: 'keine' },
    { value: 10, label: '10 kW' },
    { value: 15, label: '15 kW' },
    { value: 20, label: '20 kW' }
  ];
  const initialFormValidation = {
    terrace: false,
    balcony: false,
    area: false,
  };

  const [formData, setFormData] = React.useState<FormData>({
    garage: '',
    doppelgarage: '',
    stellplatz: '',
    tiefgaragenstellplatz: '',
    carport: '',
    ausenanlagen: false,
    photovoltaikanlage: null,
    isTerrace: null,
    terraceArea: null,
    isBalcony: null,
    balconyArea: null,
  });
  const [formValidation, setFormValidation] = React.useState<FormValidation>(initialFormValidation);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		let int = parseInt(e.target.value);
    setFormData({
      ...formData,
      [e.target.id]: (isNaN(int) || int < 0) ? '' : int
    });
  };

  const handleAusenanlagenInput = (e: {value: number, label: string}) => {
    setFormData({
      ...formData,
      ausenanlagen: !!e.value
    });
  };

  const handleSolarPanelPower = (value: number) => {
    setFormData({
      ...formData,
      photovoltaikanlage: value
    });
  };

  const handleTerraceInput = (obj: {[key: string]: boolean | number | string | undefined}) => {
    setFormData({
      ...formData,
      ...obj
    });
    clearValidation();
  };

  const validateInputs = () => {
    let hasErrors = false;
    const validation = { ...formValidation };
    if (propertyType === types[1].id) {
      if (formData.isTerrace === null) {
        validation.terrace = true;
        hasErrors = true;
      } else {
        if ((formData.isTerrace === terraceOptions[0].label || formData.isTerrace === terraceOptions[2].label) && !formData.terraceArea) {
          validation.area = true;
          hasErrors = true;
        }
      }
    }
    if (propertyType !== types[1].id) {
      if (formData.isBalcony === null) {
        validation.balcony = true;
        hasErrors = true;
      } else {
        if (formData.isBalcony && !formData.balconyArea) {
          validation.area = true;
          hasErrors = true;
        }
      }
    }

    setFormValidation(validation);
    return hasErrors;
  };

  const clearValidation = () => {
    setFormValidation(initialFormValidation);
  };

  const scrollToForm = () => {
    let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
    window.scrollTo(0, scroll_to);
  };

  const handleBackBtn = () => {
      sendMetrics("BackToSeventhStep", "MortageForm", "BackButtonPressed");
    dispatch(setFormStep(7));
    scrollToForm();
  };

  const handleContinueBtn = () => {
    let hasErrors = validateInputs();
    if (hasErrors) return;
    sendMetrics("EighthStepPassed", "MortageForm", "NextButtonPressed");

    dispatch(setAdditionalInfo(formData));
    dispatch(setFormStep(9));
    scrollToForm();
  };

  return (
    <form
      className={css(firstStepForm, props.hidden ? dnone : dblock)}
      id='formHeading'
    >
      <div className='container'>
        <Stepper />
        <div className='row'>
          <div className='col-12'>
            <h2 className={`h2 ${css(formHeading)}`}>Zusätzliche Informationen</h2>
          </div>
          <div className='col-12'>
            <div className={css(additionalInfoContainer)}>
              <div className={css(inputsContainer)}>
                <input
                  name='garage'
                  type='number'
									min={0}
                  id='garage'
                  placeholder="Garage"
									value={formData.garage}
                  className={css(customInput)}
                  onChange={handleInput}
                />
                <input
                  name='doppelgarage'
                  type='number'
									min={0}
                  id='doppelgarage'
                  placeholder="Doppelgarage"
									value={formData.doppelgarage}
                  className={css(customInput)}
                  onChange={handleInput}
                />
                <input
                  name='stellplatz'
                  type='number'
									min={0}
                  id='stellplatz'
                  placeholder="Stellplatz"
									value={formData.stellplatz}
                  className={css(customInput)}
                  onChange={handleInput}
                />
                <input
                  name='tiefgaragenstellplatz'
                  type='number'
									min={0}
                  id='tiefgaragenstellplatz'
                  placeholder="Tiefgaragenstellplatz"
									value={formData.tiefgaragenstellplatz}
                  className={css(customInput)}
                  onChange={handleInput}
                />
                <input
                  name='carport'
                  type='number'
									min={0}
                  id='carport'
                  placeholder="Carport"
									value={formData.carport}
                  className={css(customInput)}
                  onChange={handleInput}
                />
                {
                  propertyType === types[1].id ?
                  <Select 
                    classNamePrefix='customSelect'
                    options={selectOptions}
                    placeholder="Außenanlagen"
                    className={css(customSelect)}
                    onChange={handleAusenanlagenInput} /> : ""
                }
              </div>
              <div className={css(rightSection)}>
                <div className={css(solarPanelContainer)}>
                  <img src={sunImg} alt="Solar panel illustration" className="sun-image" />
                  <img src={solarPanelImg} alt="Solar panel illustration" className="solar-panel-image" />
                  <RangeSlider 
                    options={rangeInputOptions}
                    descriptionText={"Photovoltaikanlage"}
                    onChange={handleSolarPanelPower}
                  />
                </div>
                <Terrace
                  isTerrace={propertyType === types[1].id}
                  terraceOptions={terraceOptions}
                  balconyOptions={balconyOptions}
                  validation={formValidation}
                  onChange={handleTerraceInput} />
              </div>
            </div>
            <div className={css(formButton)}>
              <button
                type='button'
                className='button empty'
                onClick={handleBackBtn}
              >
                Zurück
              </button>
              <button
                type='button'
                className='button'
                onClick={handleContinueBtn}
              >
                <span>Weiter</span>
                <span className="gt">&gt;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});
