import { combineReducers } from "redux";
import { formErrorReducer } from "./formError";
import { personDateReducer } from "./personData";
import { calculatorReducer } from "./calculatorData";
import { payerDataReducer } from "./payerData";
import { purchaseNumberReducer } from "./purchaseNumber";
import { formStageReducer } from './formStage';

export const rootReducer: any = combineReducers({
  formErrorReducer,
  personDateReducer,
  calculatorReducer,
  payerDataReducer,
  purchaseNumberReducer,
  formStageReducer
});
