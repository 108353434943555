import React from "react";
import Select from 'react-select';
import { useFela } from "react-fela";
import { useSelector, useDispatch } from "react-redux";
import { setFormStep } from "redux/actions/setFormStage";
import { setPaymentData } from "redux/actions/setPayerData";
import { setPurchaseNumber, setClientSecret } from "redux/actions/setPurchaseNumber";
import { setFormError } from "redux/actions/setFormError";

import submitForm from "services/submitForm.service";
import checkIcon from "../../../assets/images/dist/checkbox-check-icon.svg";
import cartIcon from "../../../assets/images/dist/rechner/checkout-cart-icon.png";
import klarnaLogo from "../../../assets/images/dist/rechner/logo-klarna.png";
import visaLogo from "../../../assets/images/dist/rechner/logo-visa.png";
import mastercardLogo from "../../../assets/images/dist/rechner/logo-mastercard.png";
import sepaLogo from "../../../assets/images/dist/rechner/logo-sepa.png";

import {
  checkoutForm,
  checkoutHeading,
	paymentProviders,
  sectionContainer,
  paymentSectionContainer,
  imageWrapper,
  inputsWrapper,
  priceContainer,
  selectContainer,
  inputsContainer,
  customSelect,
  formFooter,
  customButton,
  agreementCheckbox,
  agreementCheckboxLabel,
} from "./styles";
import {
  firstStepForm,
  formHeading,
  formButton,
  customInput,
  dnone,
  dblock,
} from "../styles";

interface Iprops {
  hidden: boolean;
}

interface ISelectOptions {
  value: number;
  label: string;
}

interface FormValidation {
  paymentMethod: boolean;
  discount: boolean;
}

export const Checkout = React.memo((props: Iprops) => {
  const { css } = useFela();
  const dispatch = useDispatch();

  const { activeStep } = useSelector(
    (formStageObj) => formStageObj.formStageReducer
  );
  const calculatorData = useSelector(
    (formStageObj) => formStageObj.calculatorReducer
  );
  const paymentData = useSelector(
    (formStageObj) => formStageObj.payerDataReducer
  );

  const selectOptions = [
    { value: 0, label: 'Bezahlung' },
    { value: 1, label: 'Gutscheincode' }
  ];
  const initialFormValidation = {
    paymentMethod: false,
    discount: false,
  };
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = React.useState<ISelectOptions | null>(null);
  const [discount, setDiscount] = React.useState<string | undefined>(undefined);
  const [isAgreementAccepted, setIsAgreementAccepted] = React.useState<boolean>(false);
  const [formValidation, setFormValidation] = React.useState<FormValidation>(initialFormValidation);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscount(e.target.value);
    clearValidation();
  };

  const handlePaymentMethodSelect = (obj: ISelectOptions) => {
    setPaymentMethod(obj);
    clearValidation();
  };

  const validateInputs = () => {
    let hasErrors = false;
    const validation = { ...formValidation };
    Object.keys(formValidation).forEach(() => {
      if (paymentMethod === null) {
        validation.paymentMethod = true;
        hasErrors = true;
      } else if (paymentMethod.value === selectOptions[1].value && !discount) {
        validation.discount = true;
        hasErrors = true;
      }
    });

    setFormValidation(validation);
    return hasErrors;
  };

  const clearValidation = () => {
    setFormValidation(initialFormValidation);
  };

  const scrollToForm = () => {
    let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
    window.scrollTo(0, scroll_to);
  };

  const showError = (text?: string) => {
    const errorObj = {
      isError: true,
      text: 'es ist ein technischer Fehler aufgetreten, wir arbeiten bereits an einer Lösung. Probieren Sie es bitte in wenigen Minuten erneut. Vielen Dank für Ihr Verständnis.'
    };

		if (text) {
			errorObj.text = text;
		}

    dispatch(setFormError(errorObj));
  };

  const submitFormData = async () => {
    const hasErrors = validateInputs();
    if (hasErrors) return;

		setIsLoading(true);

    const payment = {
      zahlungsart: paymentMethod?.label,
      code: paymentMethod?.value === selectOptions[1].value ? discount : undefined
    };
    setDiscount(payment.code);
    dispatch(setPaymentData(payment));

    const code = payment.code === undefined ? null : payment.code;
    const filledForm = {
      Promocode: code,
      Anrede: paymentData.Anrede,
      AustAusenwaende: calculatorData.AustAusenwaende,
      AustBoden: calculatorData.AustBoden,
      AustDach: calculatorData.AustDach,
      AustDecken: calculatorData.AustDecken,
      AustFenster: calculatorData.AustFenster,
      AustHeizung: calculatorData.AustHeizung,
      AustInnen: calculatorData.AustInnen,
      AustSanit: calculatorData.AustSanit,
      AustSonstiges: calculatorData.AustSonstiges,
      Baujahr: calculatorData.Baujahr,
      Bauweise: calculatorData.Bauweise,
      Bundesland: calculatorData.Bundesland,
      Dach: calculatorData.Dach,
      EMail: paymentData.EMail,
      Grundstuecksgroesse: calculatorData.Grundstuecksgroesse,
      Hausnummer: paymentData.Hausnummer,
      HaustupGenau: calculatorData.HaustupGenau,
      Haustyp: calculatorData.Haustyp,
      IndikativerBodenrichtswert: calculatorData.IndikativerBodenrichtswert, //TODO: remove it form frontend.
      Keller: calculatorData.Keller,
      ModAwande: calculatorData.ModAwande,
      ModBad: calculatorData.ModBad,
      ModDach: calculatorData.ModDach,
      ModFenster: calculatorData.ModFenster,
      ModGrund: calculatorData.ModGrund,
      ModHeizung: calculatorData.ModHeizung,
      ModInnen: calculatorData.ModInnen,
      ModLeitung: calculatorData.ModLeitung,
      Name: paymentData.Name,
      Ort: calculatorData.Ort,
      PLZ: calculatorData.PLZ,
      Stockwerk: calculatorData.Stockwerk,
      Strasse: calculatorData.Adresse,
      Surname: paymentData.Surname,
      Telefon: paymentData.Telefon,
      Wohnflaeche: calculatorData.Wohnflaeche,
      Zus2Nutzung: calculatorData.Zus2Nutzung,
      Zus2Vermiet: calculatorData.Zus2Vermiet,
      ZusAus: calculatorData.ZusAus,
      ZusBalkonFlaeche: calculatorData.ZusBalkonFlaeche,
      ZusCarport: calculatorData.ZusCarport,
      ZusDopgarage: calculatorData.ZusDopgarage,
      ZusGarage: calculatorData.ZusGarage,
      ZusPhoto: calculatorData.ZusPhoto,
      ZusStell: calculatorData.ZusStell,
      ZusTerrasse: calculatorData.ZusTerrasse,
      ZusTerrasseFlaeche: calculatorData.ZusTerrasseFlaeche,
      ZusTief: calculatorData.ZusTief,
      ZusWbalkon: calculatorData.ZusWbalkon,
			Firma: paymentData.Firma,
			AdresseRechnung: paymentData.Strasse,
			OrtRechnung: paymentData.Stadt,
			LandRechnung: paymentData.Land,
			PLZRechnung: paymentData.paymentPlz
    };

    const resp = await submitForm(filledForm);
		saveToLocalStorage('form', filledForm);
		if (payment.zahlungsart) {
			saveToLocalStorage('paymentMethod', payment.zahlungsart );
		}

    if (resp?.clientSecret) {
			const secretArr = resp.clientSecret.match(/[a-zA-Z0-9]{2}_[a-zA-Z0-9]*_secret_[a-zA-Z0-9]*/);
			const secret = secretArr?.length ? secretArr[0] : resp?.clientSecret;
      dispatch(setClientSecret(secret));
    }

    if (resp?.purchaseNumber) {
			saveToLocalStorage('purchaseNumber', resp.purchaseNumber);
      dispatch(setPurchaseNumber(resp.purchaseNumber));
			setIsLoading(false);
      return resp;
    } else {
			setIsLoading(false);
      showError(resp.Message);
    }
  };

  const handleContinueBtn = async () => {
    const resp = await submitFormData();
    if (resp?.purchaseNumber) {
      if (paymentMethod?.value === selectOptions[0].value) {
        dispatch(setFormStep(13));
      } else {
        dispatch(setFormStep(14));
      }
      scrollToForm();
    }
  };

	const saveToLocalStorage = (key: string, payload: string | {}) => {
		return localStorage.setItem(key, JSON.stringify(payload));
	}

  React.useEffect(() => {
    setPaymentMethod(null);
    setIsAgreementAccepted(false);
  }, [activeStep]);

  return (
    <form
      className={css(firstStepForm, props.hidden ? dnone : dblock)}
      id='formHeading'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className={`h2 ${css(formHeading, checkoutHeading)}`}>Bezahlung</h2>
          </div>
          <div className={`col-12 ${css(paymentProviders)}`}>
						<img src={klarnaLogo} alt="Klarna logo" className="klarna-logo" />
						<img src={visaLogo} alt="Visa logo" className="visa-logo" />
						<img src={mastercardLogo} alt="Mastercard logo" className="mastercard-logo" />
						<img src={sepaLogo} alt="Sepa logo" className="sepa-logo" />
					</div>
          <div className='col-12'>
            <div className={css(checkoutForm)}>
              <div className={css(sectionContainer)}>
                <div className={css(imageWrapper)}>
                  <img src={cartIcon} alt="User icon" className="section-icon" />
                </div>
                <div className={css(priceContainer)}>
									<div className="price-row">
										<span className="item">Wertermittlung Ihrer Immobilie</span>
										<span className="price">EUR 15.97</span>
									</div>
									<div className="price-row">
										<span className="item">MwSt</span>
										<span className="price">EUR 3.03</span>
									</div>
									<div className="price-row">
										<span className="item">Zu bezahlender Betrag (inkl. MwSt)</span>
										<span className="price strong">EUR 19.–</span>
									</div>
                </div>
                <div className={css(inputsWrapper)}>
                  <div className={css(selectContainer)}>
                    <Select
                      classNamePrefix='customSelect'
                      options={selectOptions}
                      value={paymentMethod}
                      placeholder="Zahlungsart"
                      className={`${css(customSelect)} ${formValidation.paymentMethod ? 'has-error' : ''}`}
                      onChange={handlePaymentMethodSelect} />
                  </div>
                  <div className={css(inputsContainer)}>
                    {
                      paymentMethod?.value === selectOptions[1].value ?
                      <input
                        name='code'
                        type='text'
                        id='code'
                        placeholder="Code"
                        className={`${css(customInput)} ${formValidation.discount ? 'has-error' : ''}`}
                        onChange={handleInput}
                      /> : ""
                    }
                  </div>
                </div>
              </div>
              <div className={css(sectionContainer, paymentSectionContainer)}>
              </div>
            </div>
            <div className={css(formFooter)}>
              <div className={css(agreementCheckbox)}>
                <div className='checkbox-wrapper'>
                  <input
                    name='agreement'
                    id='agreement'
                    type='checkbox'
                    required
                    className={css(dnone)}
                    onChange={() => setIsAgreementAccepted((prev) => !prev)}
                  />
                  <label className={css(agreementCheckboxLabel)} htmlFor='agreement'>
                    {
                      isAgreementAccepted ?
                      <img src={checkIcon} alt="Check icon" className="check-icon" /> : ""
                    }
                  </label>
                </div>
                <label htmlFor='agreement'>
                  <span className='agreement-checkbox-text'>
                    Mit dieser Bestellung akzeptieren Sie unsere <a
											href="/files/pdf/AllgemeineGeschaeftsbedingungen.pdf"
											color="transparent"
											style={{
												fontWeight: 500
											}}
											target="_blank">
											Allgemeine Geschäftsbedingungen
										</a>
                  </span>
                </label>
              </div>
              <div className={`${css(formButton, customButton)} button-wrapper`}>
                <button
                  type='button'
                  className={`button ${isLoading ? 'button-loading' : ''}`}
                  disabled={!isAgreementAccepted || (isAgreementAccepted && isLoading)}
                  onClick={handleContinueBtn}
                >
                  <span>Zahlungspflichtig bestellen</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});
