import React from "react";
import Select from 'react-select'
import {useFela} from "react-fela";
import {useSelector, useDispatch} from "react-redux";
import {IdoubleRadio} from "../../../interfaces";
import {setFormStep} from "redux/actions/setFormStage";
import {setPropertySubType, setPropertyFloor} from "redux/actions/setCalculatorData";
import {types} from "../../../utils/property.constant";

import {Stepper} from "../Stepper";
import etagenwohnungIcon from "../../../assets/images/dist/rechner/second-step-etagenwohnung.svg";
import penthouseIcon from "../../../assets/images/dist/rechner/second-step-penthouse.svg";
import zweifamilienhausIcon from "../../../assets/images/dist/rechner/second-step-zweifamilienhaus.svg";
import reihenmittelhausIcon from "../../../assets/images/dist/rechner/second-step-reihenmittelhaus.svg";
import doppelhaushalfteIcon from "../../../assets/images/dist/rechner/second-step-doppelhaushalfte.svg";
import {sendMetrics} from "../../../utils/google-analytics";

import {
    radioButtonGroup,
    eigentumswohnung,
    selectContainer,
    customSelect,
    floorSelect
} from "./styles";
import {
    firstStepForm,
    secondStepForm,
    formHeading,
    formButton,
    radioLabel,
    dnone,
    dblock,
} from "../styles";

interface Iprops {
    hidden: boolean;
}

interface IFloorData {
    value: number | null;
    label: string | null;
    code: string;
}

export const SecondStep = React.memo((props: Iprops) => {
    const {css} = useFela();
    const dispatch = useDispatch();

    const selectOptions = [
        {
            value: 0,
            label: 'Erdgeschoss',
            code: 'EG'
        },
        {
            value: 1,
            label: '1. Stock',
            code: '1. OG'
        },
        {
            value: 2,
            label: '2. Stock',
            code: '2. OG'
        },
        {
            value: 3,
            label: '3. Stock',
            code: '3. OG'
        },
        {
            value: 4,
            label: 'Dachgeschoss',
            code: 'DG'
        }
    ]

//  const { activeStep } = useSelector(
//    (formStageObj) => formStageObj.formStageReducer
//  );
    // TODO: Check if this can be safely removed.
    const propertyType = useSelector(
        (propertyTypeObj) => propertyTypeObj.calculatorReducer.propertyTypeId
    );

    const [isError, setIsError] = React.useState<boolean>(false);
    const [isSelectError, setIsSelectError] = React.useState<boolean>(false);

    const [type, setType] = React.useState<IdoubleRadio>({
        active: null,
    });

    const [floor, setFloor] = React.useState<IFloorData | null>(null);

    const handleType = (e: React.ChangeEvent<HTMLInputElement>) => {
        setType({
            active: parseInt(e.target.value),
        });
        setIsError(false);
    };

    const handleFloorSelect = (floorObj: IFloorData) => {
        setFloor(floorObj);
        setIsSelectError(false);
    };

    const scrollToForm = () => {
        let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
        window.scrollTo(0, scroll_to);
    };

    const handleBackBtn = () => {
        sendMetrics("BackToFirstStep", "MortageForm", "BackButtonPressed");
        dispatch(setFormStep(1));
        scrollToForm();
    };

    const handleContinueBtn = () => {
        if (type.active === null) {
            setIsError(true);
            return;
        } else if (type.active === types[0].subTypes[0].id && (!floor || floor?.value === null)) {
            setIsSelectError(true);
            return;
        }
        const typeIndex = types.findIndex(el => el.id === propertyType);
        const subtypeIndex = types[typeIndex].subTypes.findIndex(el => el.id === type.active);
        dispatch(setPropertySubType(types[typeIndex].subTypes[subtypeIndex].name));
        sendMetrics("SecondStepPassed", "MortageForm", "NextButtonPressed");
        if (floor?.label) {
            dispatch(setPropertyFloor(floor.code));
        }
        dispatch(setFormStep(3));
        scrollToForm();
    };

    const renderOptions = () => {
        if (propertyType === types[0].id) {
            return (
                <>
                    <div className='col-6 col-md-5 col-lg-4 offset-lg-1 etagenwohnung'>
                        <input type="radio"
                               id="first-step-etagenwohnung"
                               name="type"
                               value={types[0].subTypes[0].id}
                               checked={type.active === types[0].subTypes[0].id}
                               onChange={handleType}>
                        </input>
                        <label htmlFor="first-step-etagenwohnung"
                               className={`${css(radioLabel)} ${isError ? "error" : ""}`}>
                            <img src={etagenwohnungIcon} alt={`${types[0].subTypes[0].name} option`}/>
                            <span>{types[0].subTypes[0].name}</span>
                        </label>
                        {
                            propertyType === types[0].id && type.active === types[0].subTypes[0].id ?
                                <div className={css(selectContainer)}>
                                    <Select classNamePrefix='customSelect'
                                            options={selectOptions}
                                            placeholder="Stockwerk"
                                            className={`${css(customSelect, floorSelect)} ${isSelectError ? 'has-error' : ''}`}
                                            onChange={handleFloorSelect}/>
                                </div>
                                : ""
                        }
                    </div>
                    <div className='col-6 col-md-5 col-lg-4'>
                        <input type="radio"
                               id="first-step-penthouse"
                               name="type"
                               value={types[0].subTypes[1].id}
                               checked={type.active === types[0].subTypes[1].id}
                               onChange={handleType}>
                        </input>
                        <label htmlFor="first-step-penthouse"
                               className={`${css(radioLabel)} ${isError ? "error" : ""}`}>
                            <img src={penthouseIcon} alt={`${types[0].subTypes[1].name} option`}/>
                            <span>{types[0].subTypes[1].name}</span>
                        </label>
                    </div>
                </>
            );
        } else if (propertyType === types[1].id) {
            return (
                <>
                    <div className='col-4'>
                        <input type="radio"
                               id="first-step-zweifamilienhaus"
                               name="type"
                               value={types[1].subTypes[0].id}
                               checked={type.active === types[1].subTypes[0].id}
                               onChange={handleType}>
                        </input>
                        <label htmlFor="first-step-zweifamilienhaus"
                               className={`${css(radioLabel)} ${isError ? "error" : ""}`}>
                            <img src={zweifamilienhausIcon} alt={`${types[1].subTypes[0].name} option`}/>
                            <span>{types[1].subTypes[0].name}</span>
                        </label>
                    </div>
                    <div className='col-4'>
                        <input type="radio"
                               id="first-step-reihenmittelhaus"
                               name="type"
                               value={types[1].subTypes[1].id}
                               checked={type.active === types[1].subTypes[1].id}
                               onChange={handleType}>
                        </input>
                        <label htmlFor="first-step-reihenmittelhaus"
                               className={`${css(radioLabel)} ${isError ? "error" : ""}`}>
                            <img src={reihenmittelhausIcon} alt={`${types[1].subTypes[1].name} option`}/>
                            <span>{types[1].subTypes[1].name}</span>
                        </label>
                    </div>
                    <div className='col-4'>
                        <input type="radio"
                               id="first-step-doppelhaushälfte"
                               name="type"
                               value={types[1].subTypes[2].id}
                               checked={type.active === types[1].subTypes[2].id}
                               onChange={handleType}>
                        </input>
                        <label htmlFor="first-step-doppelhaushälfte"
                               className={`${css(radioLabel)} ${isError ? "error" : ""}`}>
                            <img src={doppelhaushalfteIcon} alt={`${types[1].subTypes[2].name} option`}/>
                            <span>{types[1].subTypes[2].name}</span>
                        </label>
                    </div>
                </>
            )
        }
    };

    return (
        <form
            className={css(firstStepForm, secondStepForm, props.hidden ? dnone : dblock)}
            id='formHeading'
        >
            <div className='container'>
                <Stepper/>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className={`h2 ${css(formHeading)}`}>Art der Immobilie</h2>
                    </div>
                    <div className='col-xl-10 offset-lg-1 col-lg-11'>
                        <div className={css(radioButtonGroup, propertyType === types[0].id ? eigentumswohnung : {})}>
                            {renderOptions()}
                        </div>
                        <div className={css(formButton)}>
                            <button
                                type='button'
                                className='button empty'
                                onClick={handleBackBtn}
                            >
                                Zurück
                            </button>
                            <button
                                type='button'
                                className='button'
                                onClick={handleContinueBtn}
                            >
                                <span>Weiter</span>
                                <span className="gt">&gt;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
});
