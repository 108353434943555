import { FelaStyle } from "react-fela";

export const finalInfoContainer: FelaStyle<{}> = () => ({
  fontSize: "14px",
  margin: "2em auto",
  maxWidth: "45em",
  position: "relative",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "center",
  alignItems: "center",
  color: "#007772",
  "> .heading-wrapper": {
    fontSize: "36px",
    margin: "1em 0 1.5em 0",
    flex: "1 0 100%",
    "@media screen and (max-width: 767px)": {
      fontSize: "24px",
      lineHeight: "1.5em",
      textAlign: "center",
    },
    "> .h2": {
      fontWeight: "600",
      lineHeight: "1.75em",
    },
  },
  "> .text-wrapper": {
    "> p": {
      fontWeight: "400",
      lineHeight: "1.5em",
      "> span": {
        fontWeight: "600",
      },
    }
  },

  "> .info-wrapper": {
    width: "75%",
    marginTop: "2em",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "flex-start",
    "@media screen and (max-width: 576px)": {
      alignSelf: "flex-start",
      "> .info-text-wrapper": {
        maxHeight: "unset !important",
        "> .info-cell": {
          marginTop: "2.5em",
          "&:first-of-type": {
            marginTop: "unset !important",
          },
        },
      }
    },
    "> .info-heading": {
      margin: "2em 0",
      fontWeight: "400",
      lineHeight: "1.5em",
    },
    "> .info-text-wrapper": {
      width: "100%",
      maxHeight: "15em",
      display: "flex",
      flexFlow: "column wrap",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.5em",
      "> .info-cell": {
        display: "flex",
        flexFlow: "column wrap",
        justifyContent: "center",
        alignItems: "flex-start",
        "&:nth-of-type(2n)": {
          marginTop: "2.5em",
        },
        "> strong": {
          marginBottom: ".5em",
        }
      }
    }
  },
});
