declare global {
    interface Window {
        gtag:any;
    }
}

export const sendMetrics = (action: string, category: string, label: string) => {
    const values = {
        event_category: "",
        event_label: "",
    };
    values['event_category'] = category;
    values['event_label'] = label;
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
        window.gtag('event', action, values);
        return;
    }
//     console.log(`Type of window: ${typeof window}.`);
//     console.log(`Type of gtag: ${typeof window.gtag}.`);
    console.warn("Could not send metrics!");
}