import { FelaStyle } from "react-fela";

export const checkoutHeading: FelaStyle<{}> = () => ({
  marginTop: "unset",
});

export const checkoutForm: FelaStyle<{}> = () => ({
  marginTop: "2.5em",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  "@media screen and (max-width: 991px)": {
    marginTop: "1em",
    flexDirection: "column"
  }
});

export const paymentProviders: FelaStyle<{}> = () => ({
	width: "100%",
	marginTop: ".5em",

	"> img": {
		marginRight: "1.1em"
	}
});

export const sectionContainer: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  flex: "1 1 50%",
  "@media screen and (max-width: 991px)": {
    flexDirection: "column",
    "&:first-of-type": {
      marginTop: "unset",
    },
  }
});

export const paymentSectionContainer: FelaStyle<{}> = () => ({
  marginTop: "9.15em",
  marginLeft: "1em",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  flex: "1 1 50%",
  "&:first-of-type": {
    marginTop: "2em",
  },
  "@media screen and (max-width: 991px)": {
    marginTop: "3em",
    marginBottom: "2em",
    marginLeft: "unset",
    flexDirection: "column",
    "&:first-of-type": {
      marginTop: "unset",
    },
  }
});

export const imageWrapper: FelaStyle<{}> = () => ({
  fontSize: "14px",
  padding: "1em 0 3em 0",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  lineHeight: "1.5em",
  color: "#007772",
  "@media screen and (max-width: 576px)": {
    justifyContent: "center",
  }
});

export const inputsWrapper: FelaStyle<{}> = () => ({
  fontSize: "14px",
  maxWidth: "15em",
  paddingTop: "5em",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  "@media screen and (max-width: 576px)": {
    maxWidth: "unset",
  },
  "@media screen and (max-width: 767px)": {
    width: "75%",
  },
});

export const priceContainer: FelaStyle<{}> = () => ({
	fontSize: "14px",
	padding: "1.1em 2.9em",
	display: "flex",
	flexFlow: "column nowrap",
	backgroundColor: "#fff",
	color: "#007772",
	"@media screen and (max-width: 576px)": {
		padding: "1em 1em 1em 2em",
		"> .price-row": {
			flexWrap: "wrap",
		}
	},
	"@media screen and (min-width: 577px) and (max-width: 991px)": {
		padding: "2em 1em 2em 1.5em",
	},
	"> .price-row": {
		width: "100%",
		padding: ".5em 0",
		display: "flex",
		flexFlow: "row nowrap",
		justifyContent: "space-between",
		alignItems: "center",
		lineHeight: "1.3em",
		"> span.item": {
			fontWeight: "300"
		},
		"> span.price": {
			flexBasis: "15%",
			textAlign: "start",

			"&.strong": {
				fontWeight: "600"
			}
		},
	}
});

export const selectContainer: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
});

export const inputsContainer: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "row nowrap",
  "> input": {
    marginTop: "2.25em",
    minHeight: "unset",
    minWidth: "unset",
    height: "2.5em",
    flex: "1 1 auto",
  },
});

export const customSelect: FelaStyle<{}> = () => ({
  "&.has-error": {
    backgroundColor: "#fff6f6",
    border: "1px solid #df6969",
    borderRadius: "8px"
  },
  "> .customSelect__control": {
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%",
    height: "2.5em",
  },
  "> .customSelect__menu": {
    width: "100% !important",
  },
});

export const formFooter: FelaStyle<{}> = () => ({
  marginTop: "7em",
  minHeight: "6em",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "space-between",
  "@media screen and (max-width: 767px)": {
    marginTop: "0",
  },
  "> .button-wrapper": {
    marginTop: "1em !important"
  }
});

export const agreementCheckbox: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-start",
  flex: "1 0 50%",
  "> div.checkbox-wrapper": {
    marginRight: "1em",
    "> label > img.check-icon": {
      position: "relative",
      top: "-3px",
      right: "-3px",
      verticalAlign: "unset",
    },
  },
  "> label": {
    display: "inline-flex",
    marginTop: ".25em",
    "@media screen and (min-width: 991px)": {
      width: "45%",
    },
    "> .agreement-checkbox-text": {
      fontSize: "14px",
      width: "100%",
      fontWeight: "300",
      lineHeight: "1.5em",
      color: "#007772",
      "@media screen and (max-width: 576px)": {
        fontSize: "13px",
      },
    },
  },
});

export const agreementCheckboxLabel: FelaStyle<{}> = () => ({
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "#fff",
});

export const customButton: FelaStyle<{}> = () => ({
  justifyContent: "flex-start",

  "> button.button": {
		maxWidth: "20em",
		position: "relative",
    justifyContent: "center",

    "&:disabled": {
      color: "#97bbb8",
      cursor: "default"
    },

    "@media screen and (max-width: 576px)": {
      maxWidth: "unset",
      width:  "100%",
    },
  }
});

