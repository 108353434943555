import Geocode from "react-geocode";

interface ICoordinates {
  lat: number;
  lng: number;
};

const key = process.env.REACT_APP_GOOGLE_API_KEY;

Geocode.setApiKey(key);
Geocode.setLanguage("de");
Geocode.setRegion("de");
Geocode.setLocationType("ROOFTOP");

const getPropertyCoordinates = async (requestData: string, setCoordinates: (arg0: ICoordinates | null) => void): Promise<ICoordinates> => {
  return Geocode.fromAddress(requestData).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location;
      setCoordinates({ lat, lng });
      return { lat, lng };
    },
    (error) => {
      setCoordinates({ lat: 0, lng: 0 });
      console.error(error);
    }
  );
};

export default getPropertyCoordinates;
