import { FelaStyle } from "react-fela";

export const finalInfoContainer: FelaStyle<{}> = () => ({
  fontSize: "18px",
  margin: "9em 3em 12em 18em",
  maxWidth: "45em",
  position: "relative",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "space-between",
  "@media screen and (max-width: 991px)": {
    fontSize: "14px",
    margin: "1em 0 0 0",
    maxWidth: "100%",
    "> .heading-wrapper": {
      margin: "1em 0 2em 0",
      "> .h2": {
        fontSize: "18px",
        textAlign: "center",
      },
    },
    "> .image-wrapper": {
      textAlign: "center",
    },
    "> .text-wrapper": {
      margin: "2em 1em 0 1em",
    },
  },
  "> .heading-wrapper": {
    flex: "1 0 100%",
    marginBottom: "2em",
    "> .h2": {
      fontWeight: "600",
      color: "#007772",
    },
  },
  "> .image-wrapper": {
    position: "absolute",
    left: "-12em",
    "@media screen and (max-width: 991px)": {
      position: 'unset',
      left: "unset",
    },
  },
  "> .text-wrapper": {
    flex: "0 0 70%",
    "> p": {
      fontWeight: "400",
      lineHeight: "1.5em",
      color: "#007772",
    }
  },
});
