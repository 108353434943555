import { FelaStyle } from "react-fela";

export const additionalInfoContainer: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "space-between",
  "@media screen and (max-width: 991px)": {
    flexDirection: "column"
  }
});

export const inputsContainer: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  flexBasis: "35%",
  "@media screen and (max-width: 767px)": {
    width: "100%",
  },
  "> input": {
    marginTop: "2em",
    minHeight: "unset",
    minWidth: "unset",
    height: "2.5em",
    width: "100%",
  },
  "> .react-date-picker": {
    minHeight: "2.75em",
    padding: "0",
    "> .react-date-picker__wrapper > .react-date-picker__button": {
      marginTop: "-.25em",
      paddingRight: ".75em",
      justifyContent: "flex-end",
    }
  },
  "> .has-error": {
    backgroundColor: "#fff6f6",
    border: "1px solid #df6969",
  },
  "> div > input": {
    minHeight: "unset",
    height: "2.5em",
    width: "100%",
  }
});

export const customSelect: FelaStyle<{}> = () => ({
  marginTop: "2em",
  "&.has-error": {
    backgroundColor: "#fff6f6",
    border: "1px solid #df6969",
    borderRadius: "8px"
  },
  "> .customSelect__control": {
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%",
    height: "2.5em",
  },
  "> .customSelect__menu": {
    width: "100% !important",
  },
});

export const rightSection: FelaStyle<{}> = () => ({
  margin: "1.25em 2em 0 0",
  fontSize: "14px",
  display: "flex",
  flexFlow: "column nowrap",
  flexBasis: "50%",
  "@media screen and (min-width: 576px) and (max-width: 976px)": {
    margin: "2.5em 0 0 0",
  },
  "@media screen and (max-width: 576px)": {
    margin: "2.5em 0 0 -.5em",
  }
});

export const solarPanelContainer: FelaStyle<{}> = () => ({
  width: "100%",
  position: "relative",
  padding: "1em 2.5em",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  alignItems: "flex-end",
  backgroundColor: "#fff",
  border: "1px solid #97bbb8",
  borderRadius: "12px",
  "@media screen and (max-width: 576px)": {
    "> img.sun-image": {
      left: "20%",
    },
  },
  "@media screen and (max-width: 767px)": {
    flexDirection: "column",
    alignItems: "center",
    "> img.sun-image": {
      left: "30%",
    },
  },
  "> img.sun-image": {
    position: "absolute",
    top: "-1.8em",
    left: "1em",
  },
  "> img.solar-panel-image": {
    height: "fit-content",
  },
});

export const terraceContainer: FelaStyle<{}> = () => ({
  margin: "3em 2em",
  paddingTop: "2em",
  display: "flex",
  flexFlow: "row nowrap",
  "@media screen and (max-width: 767px)": {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "> .inputs-container": {
      marginLeft: "0",
    },
  },
  "> .inputs-container": {
    justifyContent: "flex-start",
    marginLeft: "2em",
  },
});
