interface IInitialProps {
  Anrede: string | null;
  Name: string | null;
  Surname: string | null;
  EMail: string | null;
  Telefon: string | null;
  Strasse: string | null;
  Hausnummer: string;
  paymentPlz: string | null;
  Stadt: string | null;
  Land: string | null;
	Firma?: string | null;
  Zahlungsart: string | null;
  Promocode: string | null;
}

interface IPayerAction {
  type: string,
  payload: {
    gender?: string | null;
    vorname?: string | null;
    nachname?: string | null;
    email?: string | null;
    telefonnummer?: string | null;
    firma?: string | null;
    adresszeile?: string | null;
    plz?: string | null;
    stadt?: string | null;
    land?: string | null;
    zahlungsart?: string | null;
    code?: string | null;
  }
}

const initialState: IInitialProps = {
  Anrede: null,
  Name: null,
  Surname: null,
  EMail: null,
  Telefon: null,
  Strasse: null,
  Hausnummer: '',
  paymentPlz: null,
  Stadt: null,
  Land: null,
	Firma: null,
  Zahlungsart: null,
  Promocode: null,
}

export const payerDataReducer = (state = initialState, action: IPayerAction) => {
  switch(action.type) {
    case 'SET_PAYER_DETAILS':
      return {
        ...state,
        Anrede: action.payload.gender,
        Name: action.payload.vorname,
        Surname: action.payload.nachname,
        EMail: action.payload.email,
        Telefon: action.payload.telefonnummer,
        Strasse: action.payload.adresszeile,
        paymentPlz: action.payload.plz,
        Stadt: action.payload.stadt,
        Land: action.payload.land,
        Firma: action.payload.firma,
      }
    case 'SET_PAYMENT_DETAILS':
      return {
        ...state,
        Zahlungsart: action.payload.zahlungsart,
        Promocode: action.payload.code,
      }

    default:
      return state;
  }
}