import { FelaStyle } from "react-fela";

export const checkoutHeading: FelaStyle<{}> = () => ({
  marginTop: "unset",
});

export const checkoutForm: FelaStyle<{}> = () => ({
  marginTop: "4em",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  "@media screen and (max-width: 991px)": {
    marginTop: "1em",
    flexDirection: "column"
  }
});

export const sectionContainer: FelaStyle<{}> = () => ({
  marginTop: "4em",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  flex: "1 1 50%",
  "&:first-of-type": {
    marginTop: "2em",
  },
  "@media screen and (max-width: 991px)": {
    flexDirection: "column",
    "&:first-of-type": {
      marginTop: "unset",
    },
  }
});

export const paymentSectionContainer: FelaStyle<{}> = () => ({
  marginTop: "10.5em",
  marginLeft: "1em",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  flex: "1 1 50%",
  "&:first-of-type": {
    marginTop: "2em",
  },
  "@media screen and (max-width: 991px)": {
    marginTop: "3em",
    marginBottom: "2em",
    marginLeft: "unset",
    flexDirection: "column",
    "&:first-of-type": {
      marginTop: "unset",
    },
  }
});

export const imageWrapper: FelaStyle<{}> = () => ({
  fontSize: "14px",
  padding: "1em 0 3em 0",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  lineHeight: "1.5em",
  color: "#007772",
  "@media screen and (max-width: 576px)": {
    justifyContent: "center",
  }
});

export const priceContainer: FelaStyle<{}> = () => ({
	fontSize: "14px",
	padding: "1.1em 2.9em",
	display: "flex",
	flexFlow: "column nowrap",
	backgroundColor: "#fff",
	color: "#007772",
	"@media screen and (max-width: 576px)": {
		padding: "1em 1em 1em 2em",
		"> .price-row": {
			flexWrap: "wrap",
		}
	},
	"@media screen and (min-width: 577px) and (max-width: 991px)": {
		padding: "2em 1em 2em 1.5em",
	},
	"> .price-row": {
		width: "100%",
		padding: ".5em 0",
		display: "flex",
		flexFlow: "row nowrap",
		justifyContent: "space-between",
		alignItems: "center",
		lineHeight: "1.3em",
		"> span.item": {
			fontWeight: "300"
		},
		"> span.price": {
			flexBasis: "15%",
			textAlign: "start",

			"&.strong": {
				fontWeight: "600"
			}
		},
	}
});

export const formFooter: FelaStyle<{}> = () => ({
  marginTop: "7em",
  minHeight: "6em",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "space-between",
  "@media screen and (max-width: 767px)": {
    marginTop: "0",
  },
  "> .button-wrapper": {
    marginTop: "1em !important"
  }
});
