import React from "react";
import {useFela} from "react-fela";
import {useSelector, useDispatch} from "react-redux";
import {setFormStep} from "redux/actions/setFormStage";
import magnifierImg from "../../../assets/images/dist/rechner/tenth-step-magnifier.png";
import {sendMetrics} from "../../../utils/google-analytics";

import {Stepper} from "../Stepper";

import {finalInfoContainer} from "./styles";
import {
    firstStepForm,
    formButton,
    dnone,
    dblock,
} from "../styles";

interface Iprops {
    hidden: boolean;
}

export const TenthStep = React.memo((props: Iprops) => {
    const {css} = useFela();
    const dispatch = useDispatch();

//  const { activeStep } = useSelector(
//    (formStageObj) => formStageObj.formStageReducer
//  );
    // TODO: Check if this can be safely removed.

    const scrollToForm = () => {
        let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
        window.scrollTo(0, scroll_to);
    };

    const handleBackBtn = () => {
        sendMetrics("BackToNinthStep", "MortageForm", "BackButtonPressed");
        dispatch(setFormStep(9));
        scrollToForm();
    };

    const handleContinueBtn = () => {
        sendMetrics("TenthStepPassed", "MortageForm", "NextButtonPressed");
        dispatch(setFormStep(11));
        scrollToForm();
    };

    return (
        <form
            className={css(firstStepForm, props.hidden ? dnone : dblock)}
            id='formHeading'
        >
            <div className='container'>
                <Stepper/>
                <div className='row'>
                    <div className='col-12'>
                        <div className={`${css(finalInfoContainer)}`}>
                            <div className='heading-wrapper'>
                                <h2 className="h2">
                                    Überprüfen Sie Ihre Angaben!
                                </h2>
                            </div>
                            <div className="image-wrapper">
                                <img src={magnifierImg} alt="Magnifier icon" className="magnifier-image"/>
                            </div>
                            <div className="text-wrapper">
                                <p>
                                    Sie haben alles erfolgreich ausgefüllt. Nun haben Sie die Möglichkeit, Ihre Daten
                                    mittels Anklicken auf jeweilige Schritte zu überprüfen.
                                </p>
                                <p>
                                    Falls alles richtig ausgefüllt ist, bitte auf «Weiter» drücken, um zur Bezahlung und
                                    anschliessend zu Ihrem Wertermittlungszertifikat zu gelangen.
                                </p>
                            </div>
                        </div>
                        <div className={css(formButton)}>
                            <button
                                type='button'
                                className='button empty'
                                onClick={handleBackBtn}
                            >
                                Zurück
                            </button>
                            <button
                                type='button'
                                className='button'
                                onClick={handleContinueBtn}
                            >
                                <span>Weiter</span>
                                <span className="gt">&gt;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
});
