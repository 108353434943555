interface IinitialState {
  purchaseNumber: string | null;
  clientSecret: string | null;
}

interface IPurchaseNumberAction {
  type: string;
  payload: string | null;
}

const initialState: IinitialState = {
  purchaseNumber: null,
  clientSecret: null,
};

export const purchaseNumberReducer = (
  state = initialState,
  action: IPurchaseNumberAction
) => {
  switch (action.type) {
    case "SET_PURCHASE_NUMBER": {
      return {
        ...state,
        purchaseNumber: action.payload,
      };
    }
    case "SET_CLIENT_SECRET": {
      return {
        ...state,
        clientSecret: action.payload,
      };
    }

    default:
      return state;
  }
};
