import React from "react";
import Select from 'react-select';
import { useFela } from "react-fela";

import balconyImg from "../../../assets/images/dist/rechner/eighth-step-balcony.png";
import terraceImg from "../../../assets/images/dist/rechner/eighth-step-terrace.png";

import { customSelect, terraceContainer, inputsContainer } from "./styles";
import { customInput } from "../styles";

interface Iprops {
  isTerrace?: boolean,
  validation: {
    terrace: boolean,
    balcony: boolean,
    area: boolean
  },
  terraceOptions: { value: number; label: string; }[];
  balconyOptions: { value: number; label: string; }[];
  onChange: (obj: {[key: string]: boolean | number | string | undefined}) => void,
}

export const Terrace = React.memo((props: Iprops) => {
  const { css } = useFela();
  const terraceOptions = props.terraceOptions;
  const balconyOptions = props.balconyOptions;

  const [value, setValue] = React.useState<number | string | null>(null);
  const [area, setArea] = React.useState<number | string | undefined>(undefined);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		let int = parseInt(e.target.value);
    setArea((isNaN(int) || int < 0) ? '' : int);
  };

  const handleSelectInput = (option: {value: number, label: string}) => {
    if (option.value !== terraceOptions[0].value) {
      setArea(undefined);
    }
    setValue(option.value);
  };

  const updateData = ({ isTerrace, onChange }: Iprops) => {
    const placeKey = isTerrace ? 'isTerrace' : 'isBalcony';
    const areaKey = isTerrace ? 'terraceArea' : 'balconyArea';
    const placeValue = () => {
      if (isTerrace) {
        const index = terraceOptions.findIndex(option => option.value === value);
        return terraceOptions[index]?.label;
      }
      return value !== null && (value === 0 || value > 1) ? true : false
    };
    const data = {
      [placeKey]: placeValue(),
      [areaKey]: value !== 1 ? area : undefined
    };
    onChange(data);
  };

  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    updateData(props);
  }, [value, area]);

  const renderSelect = () => {
    if (props.isTerrace) {
      return (
        <Select
          classNamePrefix='customSelect'
          options={terraceOptions}
          placeholder="Terrasse"
          className={`${css(customSelect)} ${props.validation.terrace ? 'has-error' : ''}`}
          onChange={handleSelectInput} />
      );
    } else {
      return (
        <Select
          classNamePrefix='customSelect'
          options={balconyOptions}
          placeholder="Balkon"
          className={`${css(customSelect)} ${props.validation.balcony ? 'has-error' : ''}`}
          onChange={handleSelectInput} />
      );
    }
  };

  return (
    <div className={css(terraceContainer)}>
      <div>
        {
          props.isTerrace ?
          <img src={terraceImg} alt="Terrace illustration" className="terrace-image" /> :
          <img src={balconyImg} alt="Balcony illustration" className="balcony-image" />
        }
      </div>
      <div className={`${css(inputsContainer)} inputs-container`}>
        {renderSelect()}
        {
          (value === 0 || value === 2) ?
          <input
            name='area'
            type='number'
						min={0}
            id='area'
						value={area}
            placeholder={props.isTerrace ? "Terrassegrösse (in m2)" : "Balkongröße (in m2)"}
            className={`${css(customInput)} ${props.validation.area ? 'has-error' : ''}`}
            onChange={handleInput}
          /> : ""
        }
      </div>
    </div>
  );
});
