import { FelaStyle } from "react-fela";

export const additionalInfoContainer: FelaStyle<{}> = () => ({
  fontSize: "14px",
  margin: "8em 0",
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "space-around",
  "@media screen and (max-width: 991px)": {
    margin: "3em 0 0 0",
    flexDirection: "column",
    "> .usage-type-section": {
      margin: "2em 0",
    },
  },
  "@media screen and (max-width: 767px)": {
    "> .section": {
      "> div > .labels": {
        width: "90%",
      },
      "> div > div > .track-background": {
        width: "80% !important",
        "&:before": {
          left: "-7%",
        },
      },
    },
  },
  "@media screen and (max-width: 576px)": {
    "> .section": {
      "> div > .labels": {
        marginLeft: "1em",
      },
      "> div > div > .track-background": {
        "&:before": {
          left: "-10%",
        },
      },
    },
  },
  "> .section": {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: "40%",
    "> .section-heading": {
      margin: "2em 0em",
      fontWeight: "600",
      color: "#007772"
    },
    "> img": {
      maxHeight: "9em",
    },
    "> div > .labels": {
      margin: "2em 0em 1em 2em",
      width: "87%",
      "@media screen and (max-width: 767px)": {
        width: "90%",
      },
    },
    "> div > div > .track-background": {
      margin: "0 auto",
      width: "78% !important",
      "&:before": {
        left: "-10%",
      },
    },
  },
});
