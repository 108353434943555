import React from "react";
import {useFela} from "react-fela";
import {useSelector, useDispatch} from "react-redux";
import {setFormStep} from "redux/actions/setFormStage";
import {setModernizations} from "redux/actions/setCalculatorData"
import {sendMetrics} from "../../../utils/google-analytics";

import {Stepper} from "../Stepper";
import {RadioGroup} from "./RadioGroup";

import {
    optionRow,
    option,
    optionLabel,
} from "./styles";
import {
    firstStepForm,
    formHeading,
    formButton,
    dnone,
    dblock,
} from "../styles";

interface Iprops {
    hidden: boolean;
}

export const SeventhStep = React.memo((props: Iprops) => {
    const {css} = useFela();
    const dispatch = useDispatch();

//  const { activeStep } = useSelector(
//    (formStageObj) => formStageObj.formStageReducer
//  );
    // TODO: Check if this can be safely removed.
    const calculatorData = useSelector(
        (calculatorDataObj) => calculatorDataObj.calculatorReducer
    );

    const modernizations = [
        {
            id: 0,
            rating: calculatorData && calculatorData.ModDach ? calculatorData.ModDach : 1,
            name: "ModDach",
            label: 'Dacherneuerung inklusive Verbesserung der Wärmedämmung'
        },
        {
            id: 1,
            rating: calculatorData && calculatorData.ModFenster ? calculatorData.ModFenster : 1,
            name: "ModFenster",
            label: 'Modernisierung der Fenster und Außentüren'
        },
        {
            id: 2,
            rating: calculatorData && calculatorData.ModLeitung ? calculatorData.ModLeitung : 1,
            name: "ModLeitung",
            label: 'Modernisierung der Leitungssysteme (Strom, Gas, Wasser, Abwasser)'
        },
        {
            id: 3,
            rating: calculatorData && calculatorData.ModHeizung ? calculatorData.ModHeizung : 1,
            name: "ModHeizung",
            label: 'Modernisierung der Heizungsanlage'
        },
        {
            id: 4,
            rating: calculatorData && calculatorData.ModAwande ? calculatorData.ModAwande : 1,
            name: "ModAwande",
            label: 'Wärmedämmung der Außenwände'
        },
        {
            id: 5,
            rating: calculatorData && calculatorData.ModBad ? calculatorData.ModBad : 1,
            name: "ModBad",
            label: 'Modernisierung von Bädern'
        },
        {
            id: 6,
            rating: calculatorData && calculatorData.ModInnen ? calculatorData.ModInnen : 1,
            name: "ModInnen",
            label: 'Modernisierung des Innenausbaus, z. B. Decken, Fußböden, Treppen'
        },
        {
            id: 7,
            rating: calculatorData && calculatorData.ModGrund ? calculatorData.ModGrund : 1,
            name: "ModGrund",
            label: 'Wesentliche Verbesserung der Grundrissgestaltung'
        },
    ];

    const scrollToForm = () => {
        let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
        window.scrollTo(0, scroll_to);
    };

    const handleRatingChange = (rating: number, id: number) => {
        modernizations.forEach(el => {
            if (el.id === id) {
                el.rating = rating;
            }
        });
    };

    const handleBackBtn = () => {
        sendMetrics("BackToSixthStep", "MortageForm", "BackButtonPressed");
        dispatch(setFormStep(6));
        scrollToForm();
    };

    const handleContinueBtn = () => {
        const rating: { [key: string]: number } = {};
        modernizations.forEach(item => rating[item.name] = item.rating);
        sendMetrics("SeventhStepPassed", "MortageForm", "NextButtonPressed");
        dispatch(setModernizations(rating));
        dispatch(setFormStep(8));
        scrollToForm();
    };

    const renderOptions = () => {
        const options: JSX.Element[] = [];
        modernizations.forEach((el) => {
            options.push(
                <div className={css(optionRow)} key={el.id}>
                    <div className={css(option)}>
            <span className={css(optionLabel)}>
              {el.label}
            </span>
                    </div>
                    <RadioGroup
                        id={el.id}
                        showLabel={el.id > 0 ? false : true}
                        onSubmit={handleRatingChange}/>
                </div>
            );
        });
        return options;
    }


    return (
        <form
            className={css(firstStepForm, props.hidden ? dnone : dblock)}
            id='formHeading'
        >
            <div className='container'>
                <Stepper/>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className={`h2 ${css(formHeading)}`}>Modernisierungen</h2>
                    </div>
                    <div className='col-lg-11'>
                        {renderOptions()}
                        <div className={css(formButton)}>
                            <button
                                type='button'
                                className='button empty'
                                onClick={handleBackBtn}
                            >
                                Zurück
                            </button>
                            <button
                                type='button'
                                className='button'
                                onClick={handleContinueBtn}
                            >
                                <span>Weiter</span>
                                <span className="gt">&gt;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
});
