import { FelaStyle } from "react-fela";

export const formDate: FelaStyle<{}> = () => ({
  display: "block",
  width: "100%",
  textAlign: "left",
  backgroundColor: '#fff',
  "> span > div": {
    bottom: 'unset !important'
  },
  "> .react-date-picker__wrapper": {
    border: 'none'
  },
});

export const radioButtonGroup: FelaStyle<{}> = () => ({
  marginTop: "3em",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  "> div": {
    "@media screen and (max-width: 768px)": {
      padding: ".1em",
    },
    "> input": {
      display: "none",
      ":checked + label": {
        backgroundColor: "#fff",
      },
    },
  },
});

export const conf: FelaStyle<{}> = () => ({
  display: "flex",
  marginBottom: "30px",
  "> .conf-check": {
    flexBasis: "5%",
    "@media screen and (max-width: 768px)": {
      flexBasis: "10%",
    },
    "@media screen and (max-width: 480px)": {
      flexBasis: "12% !important",
    },
    "> input": {
      display: "none",
      ":checked + label > span:first-child": {
        background: "#e3ecdc",
        borderColor: "#e3ecdc",
        "> svg": {
          strokeDashoffset: 0,
        },
      },
    },
  },
  "> .conf-text": {
    fontSize: "14px",
    margin: 0,
    color: "#757575",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "300",
    "@media screen and (max-width: 480px)": {
      fontSize: "12px",
    },
  },

  "@media (max-width: 576px)": {
    "> .conf-check": {
      flexBasis: '6% !important'
    },
    "> label > .conf-text": {
      fontSize: '12px !important'
    }
  }
});

export const cbx: FelaStyle<{}> = () => ({
  margin: "auto",
  userSelect: "none",
  cursor: "pointer",
  "> span": {
    display: "inline-block",
    verticalAlign: "middle",
    transform: "translate3d(0, 0, 0)",
    ":first-child": {
      position: "relative",
      width: "18px",
      height: "18px",
      borderRadius: "3px",
      transform: "scale(1)",
      verticalAlign: "middle",
      border: "1px solid #9098a9",
      transition: "all 0.2s ease",
      boxShadow: "0px 1px 15px 1px rgba(34, 60, 80, 0.2)",
      "> svg": {
        position: "absolute",
        top: "3px",
        left: "2px",
        fill: "none",
        stroke: "#ffffff",
        strokeWidth: "2",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "16px",
        strokeDashoffset: "16px",
        transition: "all 0.3s ease",
        transitionDelay: "0.1s",
        transform: "translate3d(0, 0, 0)",
      },
      ":before": {
        content: '""',
        width: "100%",
        height: "100%",
        background: "#e3ecdc",
        display: "block",
        transform: "scale(0)",
        opacity: "1",
        borderRadius: "50%",
      },
    },
    ":last-child": {
      paddingLeft: "8px",
    },
  },
  ":hover > span:first-child": {
    borderColor: "#e3ecdc",
  },
});
