import React, { useState, useEffect } from "react";
import ReactTooltip from 'react-tooltip';
import { useFela } from "react-fela";
import { rating } from "./styles";

interface Iprops {
  tooltipsContent: { [key: number]: string };
  buildingPartId: number;
  numOfParts: number;
  onSubmit: (arg0: number) => void;
}

export const Rating = React.memo((props: Iprops) => {
  const { css } = useFela();
  const [grade, setGrade] = useState<number>(0);
  const [steps, setSteps] = useState<JSX.Element[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getTooltipContent = (i: number) => {
    return props.tooltipsContent[i];
  };

  const onStepChange = (i: number) => {
    if (props.buildingPartId === props.numOfParts-1) {
      setGrade(i);
    }
    props.onSubmit(i);
  };

  const renderRating = (partId: number) => {
    const steps: JSX.Element[] = [];
    for (let i = 1; i <= 5; i++) {
      steps.push(
        <React.Fragment key={`fragment-${i}`}>
          <span 
            className={`step step-${i} ${(grade === i) ? 'step-active' : ''}`}
            onClick={() => onStepChange(i)}
            id={`${partId}-${i}`}
            key={`${partId}-${i}`}
            data-tip={`tooltip-step-${partId}-${i}-${grade}`}
            data-for={`tooltip-step-${partId}-${i}`}>
            <span className="step-number">{i}</span>
          </span>
          <ReactTooltip
            place="bottom"
            type="light"
            effect="solid"
            id={`tooltip-step-${partId}-${i}`}
            getContent={() => getTooltipContent(i)}/>
        </React.Fragment>
      );
    }
    return steps;
  };

  useEffect(() => {
    setIsLoading(true);
    const newSteps = renderRating(props.buildingPartId);
    setSteps(newSteps);
  }, [props.buildingPartId, grade]);

  useEffect(() => {
    setIsLoading(false);
  }, [steps]);

  if (isLoading) {
    return (<></>);
  } else {
    return (
      <div className={css(rating)}>
        {steps}
      </div>
    );
  }

});
