import { FelaStyle } from "react-fela";

export const formSubheading: FelaStyle<{}> = () => ({
  fontSize: "14px",
  position: "relative",
  top: "-1em",
  lineHeight: "1.5em",
  fontWeight: "400",
  color: "#007772",
  "@media screen and (max-width: 576px)": {
    position: "unset",
    top: "unset",
  },
});

export const sectionContainer: FelaStyle<{}> = () => ({
  marginTop: "4em",
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "flex-start",
  "&:first-of-type": {
    marginTop: "2em",
  },
  "@media screen and (max-width: 991px)": {
    flexDirection: "column"
  }
});

export const imageWrapper: FelaStyle<{}> = () => ({
  fontSize: "14px",
  padding: ".75em 2em 0 0",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  alignItems: "center",
  flexBasis: "20%",
  lineHeight: "1.5em",
  color: "#007772",
  "> .section-icon-label": {
    marginTop: "1em",
  },
  "@media screen and (max-width: 767px)": {
    padding: "0",
  }
});

export const inputsWrapper: FelaStyle<{}> = () => ({
  width: "70%",
  marginLeft: "2em",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",

  "@media screen and (max-width: 767px)": {
    width: "100%",
    marginTop: "1em",
    marginLeft: "0",
  },
});

export const selectContainer: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
});

export const inputsContainer: FelaStyle<{}> = () => ({
  maxWidth: "70%",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  "@media screen and (max-width: 576px)": {
    flexFlow: "row wrap",
    "> input": {
      width: "100%",
      flexBasis: "auto",
    },
  },
  "@media screen and (min-width: 576px) and (max-width: 1199px)": {
    "> input": {
      marginLeft: "1em",
    },
  },
  "@media screen and (max-width: 1199px)": {
    maxWidth: "100%",
  },
  "> input": {
    marginTop: "2em",
    minHeight: "unset",
    height: "2.5em",
    flex: "0 1 45%",
    "&#firma": {
      marginTop: "0",
    },
  },
  "> .has-error": {
    backgroundColor: "#fff6f6",
    border: "1px solid #df6969",
  },
  "> div > input": {
    minHeight: "unset",
    height: "2.5em",
    width: "100%",
  }
});

export const customSelect: FelaStyle<{}> = () => ({
  maxWidth: "6em",
  "@media screen and (min-width: 576px) and (max-width: 1199px)": {
    marginLeft: ".75em",
  },
  "> .customSelect__control": {
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%",
    height: "2.5em",
  },
  "> .customSelect__menu": {
    width: "100% !important",
  },
});
