import { FelaStyle } from "react-fela";

export const rating: FelaStyle<{}> = () => ({
  margin: "1em 0 4em 0",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "flex-end",
  flexBasis: "60%",
  "> .step": {
    fontSize: "36px",
    width: "100%",
    margin: "0 .1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    color: "#007772",
    fontWeight: "700",
    border: "1px solid #007772",
    borderRadius: "20px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#fff",
      border: "none",
    },
    "&.step-active": {
      backgroundColor: "#fff",
      border: "none",
    },
    "@media screen and (max-width: 576px)": {
      fontSize: "24px",
    },
    "&.step-1": {
      height: "3em",
    },
    "&.step-2": {
      height: "4.5em",
    },
    "&.step-3": {
      height: "6em",
    },
    "&.step-4": {
      height: "7.5em",
    },
    "&.step-5": {
      height: "9em",
    },
    "> .step-number": {
      marginBottom: ".5em",
      fontSize: "36px",
    },
  },
  "> div.__react_component_tooltip": {
    maxWidth: "50em",
    fontSize: "12px",
    padding: "1em",
    display: "flex",
    flexFlow: "column nowrap",
    lineHeight: "1.25em",
    borderRadius: "8px",
    color: "#007772",
  },
});

export const ratingStepContainer: FelaStyle<{}> = () => ({
  marginLeft: "2em",
  width: "100%",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  "@media screen and (max-width: 767px)": {
    marginTop: "1.5em",
    marginLeft: "0",
    flexFlow: "column nowrap",
  },
});

export const ratingStepImage: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "center",
    "> img": {
      width: "100%",
    },
  "@media screen and (max-width: 767px)": {
    "> img": {
      width: "70%",
    },
  },
});
