import React from "react";
import { useFela } from "react-fela";
import { useSelector, useDispatch } from "react-redux";
import { setFormError } from "redux/actions/setFormError";

import { formErrorModal } from "./MortageForm/styles";

export const ErrorModal = React.memo(() => {
  const { css } = useFela();
  const dispatch = useDispatch();
  const { isError, text } = useSelector((isErrorObj) => isErrorObj.formErrorReducer);

  const closeError = () => {
    const noErrorObj = {
      isError: false,
      text: ''
    };

    dispatch(setFormError(noErrorObj));
  };

  return (
    <>
      <div className={`${isError ? "active" : ""} ${css(formErrorModal)}`}>
        <p>
          Sehr geehrter Nutzer,
        </p>
        <p>
          {text}
        </p>
        <button
          type="button"
          onClick={closeError}
        >
          OK
        </button>
      </div>
    </>
  );
});
