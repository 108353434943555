import React from "react";
import { useFela } from "react-fela";
import { useSelector, useDispatch } from "react-redux";
import { setFormStep } from "redux/actions/setFormStage";
import { setUsageInfo } from "redux/actions/setCalculatorData";
import { sendMetrics } from "../../../utils/google-analytics";

import { Stepper } from "../Stepper";
import { RangeSlider } from "../RangeSlider";
import usabilityImg from "../../../assets/images/dist/rechner/ninth-step-usability.png";
import usageImg from "../../../assets/images/dist/rechner/ninth-step-usage.png";

import { additionalInfoContainer } from "./styles";
import {
  firstStepForm,
  formHeading,
  formButton,
  dnone,
  dblock,
} from "../styles";

interface Iprops {
  hidden: boolean;
}

export const NinthStep = React.memo((props: Iprops) => {
  const { css } = useFela();
  const dispatch = useDispatch();

//  const { activeStep } = useSelector(
//    (formStageObj) => formStageObj.formStageReducer
//  );
    // TODO: Check if this can be safely removed.

  const rangeUsabilityOptions = [
    { value: 0, label: 'schlecht' },
    { value: 1, label: 'mittel' },
    { value: 2, label: 'gut' },
    { value: 3, label: 'sehr gut' }
  ]; 
  const rangeUsageTypeOptions = [
    { value: 0, label: 'vermietet' },
    { value: 1, label: 'eigengenutzt' },
    { value: 2, label: 'beides' }
  ]; 

  const [usability, setUsability] = React.useState<number>(0);
  const [usageType, setUsageType] = React.useState<number>(0);

  const handleUsabilityInput = (value: number) => {
    setUsability(value);
  };

  const handleUsageTypeInput = (value: number) => {
    setUsageType(value);
  };

  const scrollToForm = () => {
    let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
    window.scrollTo(0, scroll_to);
  };

  const handleBackBtn = () => {
      sendMetrics("BackToEighthStep", "MortageForm", "BackButtonPressed");
    dispatch(setFormStep(8));
    scrollToForm();
  };

  const handleContinueBtn = () => {
    const usabilityIndex = rangeUsabilityOptions.findIndex(option => option.value === usability);
    const usageTypeIndex = rangeUsageTypeOptions.findIndex(option => option.value === usageType);
    let usabilityLabel = rangeUsabilityOptions[usabilityIndex].label;
    let usageTypeLabel = rangeUsageTypeOptions[usageTypeIndex].label;
    const usageInfo = {
      usability: usabilityLabel,
      usageType: usageTypeLabel || ''
    };
    sendMetrics("NinthStepPassed", "MortageForm", "NextButtonPressed");
    dispatch(setUsageInfo(usageInfo))
    dispatch(setFormStep(10));
    scrollToForm();
  };

  return (
    <form
      className={css(firstStepForm, props.hidden ? dnone : dblock)}
      id='formHeading'
    >
      <div className='container'>
        <Stepper />
        <div className='row'>
          <div className='col-12'>
            <h2 className={`h2 ${css(formHeading)}`}>Zusätzliche Informationen</h2>
          </div>
          <div className='col-12'>
            <div className={css(additionalInfoContainer)}>
              <div className="section usability-section">
                <img src={usabilityImg} alt="Usability illustration" className="usability-image" />
                <span className="section-heading">
                  Vermiet-/verwertbarkeit
                </span>
                <RangeSlider
                  options={rangeUsabilityOptions}
                  onChange={handleUsabilityInput}
                />
              </div>
              <div className="section usage-type-section">
                <img src={usageImg} alt="Usage type illustration" className="usage-type-image" />
                <span className="section-heading">
                  Nutzungsart
                </span>
                <RangeSlider
                  options={rangeUsageTypeOptions}
                  onChange={handleUsageTypeInput}
                />
              </div>
            </div>
            <div className={css(formButton)}>
              <button
                type='button'
                className='button empty'
                onClick={handleBackBtn}
              >
                Zurück
              </button>
              <button
                type='button'
                className='button'
                onClick={handleContinueBtn}
              >
                <span>Weiter</span>
                <span className="gt">&gt;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});
