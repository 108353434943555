import React from "react";
import { useFela } from "react-fela";
import { useSelector, useDispatch } from "react-redux";
import { setFormStep } from "redux/actions/setFormStage";
import { setPurchaseNumber, setClientSecret } from "redux/actions/setPurchaseNumber";
import { sendMetrics } from "../../../utils/google-analytics";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { StripeForm } from "./StripeForm";

import cartIcon from "../../../assets/images/dist/rechner/checkout-cart-icon.png";

import {
  checkoutForm,
  checkoutHeading,
  sectionContainer,
  paymentSectionContainer,
  imageWrapper,
  priceContainer,
  formFooter,
} from "./styles";
import {
  firstStepForm,
  formHeading,
  formButton,
  dnone,
  dblock,
} from "../styles";

interface Iprops {
  hidden: boolean;
}

interface IStripeForm {
  submitPayment: () => void;
}

const stripeKey = process.env.REACT_APP_STRIPE ? process.env.REACT_APP_STRIPE : '';
const stripePromise = loadStripe(stripeKey);

export const StripeStep = React.memo((props: Iprops) => {
  const { css } = useFela();
  const dispatch = useDispatch();
  const stripeRef = React.useRef<IStripeForm>(null);

  const { clientSecret } = useSelector(
    (clientSecretObj) => clientSecretObj.purchaseNumberReducer
  );

  const [isLoading, setIsLoading] = React.useState<Boolean>(true);

  const stripeOptions = {
		clientSecret: clientSecret,
		locale: 'de'
	};

  const scrollToForm = () => {
    let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
    window.scrollTo(0, scroll_to);
  };

  const clearPurchaseData = () => {
    dispatch(setClientSecret(null));
    dispatch(setPurchaseNumber(null));
  };

  const handleContinueBtn = async () => {
		if (!stripeRef.current) {
			handleBackBtn();
		}
    const resp = await stripeRef.current?.submitPayment();
        sendMetrics("PaymentStepPassed", "Payment", "NextButtonPressed");
    if (resp !== undefined) {
      dispatch(setFormStep(14));
      scrollToForm();
    }
  };

  const handleBackBtn = () => {
      sendMetrics("BackToTenthStep", "MortageForm", "BackButtonPressed");
    clearPurchaseData();
    dispatch(setFormStep(12));
    scrollToForm();
  };

  React.useEffect(() => {
    if (clientSecret?.length) {
      setIsLoading(false);
    }
  }, [clientSecret]);

  return (
    <form
      className={css(firstStepForm, props.hidden ? dnone : dblock)}
      id='formHeading'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className={`h2 ${css(formHeading, checkoutHeading)}`}>Bezahlung</h2>
          </div>
          <div className='col-12'>
            <div className={css(checkoutForm)}>
              <div className={css(sectionContainer)}>
                <div className={css(imageWrapper)}>
                  <img src={cartIcon} alt="User icon" className="section-icon" />
                </div>
                <div className={css(priceContainer)}>
									<div className="price-row">
										<span className="item">Wertermittlung Ihrer Immobilie</span>
										<span className="price">EUR 15.97</span>
									</div>
									<div className="price-row">
										<span className="item">MwSt</span>
										<span className="price">EUR 3.03</span>
									</div>
									<div className="price-row">
										<span className="item">Zu bezahlender Betrag (inkl. MwSt)</span>
										<span className="price strong">EUR 19.–</span>
									</div>
                </div>
              </div>
              <div className={css(sectionContainer, paymentSectionContainer)}>
                {
                  !isLoading ?
                  <Elements
                    stripe={stripePromise}
                    options={stripeOptions}>
                    <StripeForm ref={stripeRef} />
                  </Elements>
                  : ''
                }
              </div>
            </div>
            <div className={css(formFooter)}>
              <div className={css(formButton)}>
                <button
                  type='button'
                  className='button empty'
                  onClick={handleBackBtn}
                >
                  Zurück
                </button>
                <button
                  type='button'
                  className='button'
                  onClick={handleContinueBtn}
                >
                  <span>Weiter</span>
                  <span className="gt">&gt;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});
