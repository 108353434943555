interface IinitialState {
  activeStep: number;
  numOfSteps: number;
  initFormData: boolean;
}

interface IformStageAction {
  type: "SET_FORM_STEP" | "LOAD_INITIAL_STEPS";
  payload: number | boolean;
}

const initialState: IinitialState = {
  activeStep: 1,
  numOfSteps: 10,
	initFormData: false
};

export const formStageReducer = (
  state = initialState,
  action: IformStageAction
) => {
  switch (action.type) {
    case "SET_FORM_STEP": {
      return {
        ...state,
        activeStep: action.payload,
      };
    }

    case "LOAD_INITIAL_STEPS": {
      return {
        ...state,
        initFormData: action.payload,
      };
    }

    default:
      return state;
  }
};
