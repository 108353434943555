export const types = [
  {
    name: "Wohnung",
    id: 0,
    subTypes: [
      {
        name: "Eigentumswohnung",
        id: 7,
      },
      {
        name: "Penthouse",
        id: 8,
      }
    ]
  },
  {
    name: "Haus",
    id: 1,
    subTypes: [
      {
        name: "Ein-/Zweifamilienhaus",
        id: 4,
      },
      {
        name: "Reihenmittelhaus",
        id: 5,
      },
      {
        name: "Doppelhaushälfte/Reihenendhaus",
        id: 6,
      },
    ]
  }
];

export const propertyConditionTooltips = {
  ausenwaende: {
    id: 0,
    content: {
      1: "Holzfachwerk, Ziegelmauerwerk; Fugenglattstrich, Putz, Verkleidung mit Faserzementplatten, Bitumenschindeln oder einfachen Kunststoffplatten; kein oder deutlich nicht zeitgemäßer Wärmeschutz (vor ca. 1980)",
      2: "Ein- oder zweischaliges Mauerwerk, z.B. Gitterziegel oder Hohlblocksteine; verputzt und gestrichen oder Holzverkleidung; nicht zeitgemäßer Wärmeschutz (vor ca. 1995)",
      3: "Ein- oder zweischaliges Mauerwerk, z.B. aus Leichtziegeln, Kalksandsteinen, Gasbetonsteinen; Edelputz; Wärmedämmverbundsystem oder Wärmedämmputz (nach ca. 1995)",
      4: "Verblendmauerwerk, zweischalig, hinterlüftet, Vorhangfassade (z.B. Naturschiefer); Wärmedämmung (nach ca. 2005)",
      5: "Aufwendig gestaltete Fassaden mit konstruktiver Gliederung (Säulenstellungen, Erker etc.), Sichtbeton-Fertigteile, Natursteinfassade, Elemente aus Kupfer- oder Eloxalblech, mehrgeschossige Glasfassaden; Dämmung im Passivhausstandard",
    },
  },
  dach: {
    id: 1,
    content: {
      1: "Dachpappe, Faserzementplatten oder Wellplatten; keine bis geringe Dachdämmung",
      2: "Einfache Betondachsteine oder Tondachziegel, Bitumenschindeln; nicht zeitgemäße Dachdämmung (vor ca. 1995)",
      3: "Faserzement-Schindeln, beschichtete Betondachsteine und Tondachziegel, Folienabdichtung; Rinnen und Fallrohre aus Zinkblech; Dachdämmung (nach ca. 1995)",
      4: "Glasierte Tondachziegel, Flachdachausbildung tlw. als Dachterrassen; Konstruktion in Brettschichtholz, schweres Massivflachdach; besondere Dachformen, z. B. Mansarden-, Walmdach; Aufsparrendämmung, überdurchschnittliche Dämmung (nach ca. 2005)",
      5: "Hochwertige Eindeckung z. B. aus Schiefer oder Kupfer, Dachbegrünung, befahrbares Flachdach; aufwendig gegliederte Dachlandschaft, sichtbare Bogendachkonstruktionen; Rinnen und Fallrohre aus Kupfer; Dämmung im Passivhausstandard",
    },
  },
  fenster: {
    id: 2,
    content: {
      1: "Holzbalkendecken ohne Füllung, Spalierputz; Weichholztreppen in einfacher Art und Ausführung; kein Trittschallschutz",
      2: "Holzbalkendecken mit Füllung, Kappendecken; Stahl- oder Hartholztreppen in einfacher Art und Ausführung",
      3: "Beton- und Holzbalkendecken mit Tritt- und Luftschallschutz (z.B. schwimmender Estrich); geradläufige Treppen aus Stahlbeton oder Stahl, Harfentreppe, Trittschallschutz",
      4: "Decken mit größerer Spannweite, Deckenverkleidung (Holzpaneele oder Kassetten); gewendelte Treppen aus Stahlbeton oder Stahl, Hartholztreppenanlage in besserer Art und Ausführung",
      5: "Decken mit großen Spannweiten, gegliedert, Deckenvertäfelungen (Edelholz, Metall); breite Stahlbeton-, Metall- oder Hartholztreppenanlage mit hochwertigem Geländer",
    },
  },
  innen: {
    id: 3,
    content: {
      1: "Fachwerkwände, einfache Putze oder Lehmputze, einfache Kalkanstriche; Füllungstüren, gestrichen, mit einfachen Beschlägen ohne Dichtungen",
      2: "Massive tragende Innenwände, nicht tragende Wände in Leichtbauweise (z.B. Holzständerwände mit Gipskarton), Gipsdielen; leichte Türen, Stahlzargen",
      3: "Nicht tragende Innenwände in massiver Ausführung bzw. mit Dämmmaterial gefüllte Ständerkonstruktionen; schwere Türen, Holzzargen",
      4: "Sichtmauerwerk, Wandvertäfelungen (Holzpaneele); Massivholztüren, Schiebetürelemente, Glastüren, strukturierte Türblätter",
      5: "Gestaltete Wandabläufe (z.B. Pfeilervorlagen, abgesetzte oder geschwungene Wandpartien); Vertäfelungen (Edelholz, Metall), Akkustikputz, Brandschutzerkleidung; raumhohe aufwendige Türelemente",
    },
  },
  decken: {
    id: 4,
    content: {
      1: "Holzbalkendecken ohne Füllung, Spalierputz; Weichholztreppen in einfacher Art und Ausführung; kein Trittschallschutz",
      2: "Holzbalkendecken mit Füllung, Kappendecken; Stahl- oder Hartholztreppen in einfacher Art und Ausführung",
      3: "Beton- und Holzbalkendecken mit Tritt- und Luftschallschutz (z.B. schwimmender Estrich); geradläufige Treppen aus Stahlbeton oder Stahl, Harfentreppe, Trittschallschutz",
      4: "Decken mit größerer Spannweite, Deckenverkleidung (Holzpaneele oder Kassetten); gewendelte Treppen aus Stahlbeton oder Stahl, Hartholztreppenanlage in besserer Art und Ausführung",
      5: "Decken mit großen Spannweiten, gegliedert, Deckenvertäfelungen (Edelholz, Metall); breite Stahlbeton-, Metall- oder Hartholztreppenanlage mit hochwertigem Geländer",
    },
  },
  boden: {
    id: 5,
    content: {
      1: "Ohne Belag",
      2: "Linoleum-, Teppich-, Laminat- und PVC-Böden einfacher Art und Ausführung",
      3: "Linoleum-, Teppich-, Laminat- und PVC-Böden besserer Art und Ausführung, Fliesen, Kunststeinplatten",
      4: "Natursteinplatten, Fertigparkett, hochwertige Fliesen, Terrazzobelag, hochwertige Massivholzböden auf gedämmter Unterkonstruktion",
      5: "Hochwertiges Parkett, hochwertige Natursteinplatten, hochwertige Edelholzböden auf gedämmter Unterkonstruktion",
    },
  },
  sanit: {
    id: 6,
    content: {
      1: "Einfaches Bad mit Stand-WC; Installation auf Putz, Ölfarbenanstrich, einfache PVC-Bodenbeläge",
      2: "1 Bad mit WC, Dusche oder Badewanne; einfache Wand- und Bodenfliesen, teilweise gefliest",
      3: "1 Bad mit WC, Dusche und Badewanne, Gäste-WC; Wand- und Bodenfliesen, raumhoch gefliest",
      4: "1–2 Bäder mit tlw. zwei Waschbecken, tlw. Bidet oder Urinal, Gäste-WC, bodengleiche Dusche; Wand- und Bodenfliesen; jeweils in gehobener Qualität",
      5: "Mehrere großzügige, hochwertige Bäder, Gäste-WC; hochwertige Wand- und Bodenplatten (oberflächenstrukturiert, Einzel- und Flächendekors)",
    },
  },
  heizung: {
    id: 7,
    content: {
      1: "Einzelöfen, Schwerkraftheizung",
      2: "Fern- oder Zentralheizung, einfache Warmluftheizung, einzelne Gasaußenwand-thermen, Nachtstromspeicher-, Fußbodenheizung (vor ca. 1995)",
      3: "Elektronisch gesteuerte Fern- oder Zentralheizung, Niedertemperatur- oder Brennwertkessel",
      4: "Fußbodenheizung, Solarkollektoren für Warmwassererzeugung, zusätzlicher Kaminanschluss",
      5: "Solarkollektoren für Warmwassererzeugung und Heizung, Blockheizkraftwerk, Wärmepumpe, Hybrid-Systeme; aufwendige zusätzliche Kaminanlage",
    },
  },
  sonstiges: {
    id: 8,
    content: {
      1: "Sehr wenige Steckdosen, Schalter und Sicherungen, kein Fehlerstromschutzschalter (FI-Schalter), Leitungen teilweise auf Putz",
      2: "Wenige Steckdosen, Schalter und Sicherungen",
      3: "Zeitgemäße Anzahl an Steckdosen und Lichtauslässen, Zählerschrank (ab 1985) mit Unterverteilung und Kippsicherungen",
      4: "Zahlreiche Steckdosen und Lichtauslässe, hochwertige Abdeckungen, dezentrale Lüftung mit Wärmetauscher, mehrere LAN- und Fernsehanschlüsse",
      5: "Video- und zentrale Alarmanlage, zentrale Lüftung mit Wärmetauscher, Klimaanlage, Bussystem",
    },
  },
};
