import React, { useEffect, useState, useRef } from "react";
import { Wrapper} from "@googlemaps/react-wrapper";

interface ICoordinates {
  lat: number;
  lng: number;
}

interface MapProps extends google.maps.MapOptions {
  style?: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  center: ICoordinates;
  zoom: number;
}

const MapComponent: React.FC<MapProps> = ({
  center,
  zoom,
  children,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        center,
        zoom,
      }));
    } else if (map && center && zoom) {
      map.setCenter(center);
      map.setZoom(zoom);
    }
  }, [ref, map, center, zoom]);

  useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  return (
    <>
      <div ref={ref} id="map" />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
}

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export const GoogleMap = React.memo((props: { coordinates: ICoordinates | null }) => {
  const key = process.env.REACT_APP_GOOGLE_API_KEY;
  const [zoom, setZoom] = React.useState<number>(6);
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({ lat: 50.846023, lng: 10.239397 });
  let latLng = null;
  if (props.coordinates) {
    latLng = new google.maps.LatLng(props.coordinates.lat, props.coordinates.lng);
  }

  React.useEffect(() => {
    if (props.coordinates) {
      setCenter(props.coordinates);
      setZoom(14);
    } else {
      setZoom(6);
    }
  }, [props.coordinates]);

  return (
    <Wrapper apiKey={key}>
      <MapComponent center={center} zoom={zoom}>
        <Marker position={latLng} />
      </MapComponent>
    </Wrapper>
  );
});
