import React from "react";
//import { useDispatch } from 'react-redux'; 
import {Link} from 'react-router-dom';
import {sendMetrics} from "../utils/google-analytics";


import logoSvg from "../assets/images/dist/logo.svg";
import logoMiniSvg from "../assets/images/dist/logo-mini.svg";
import logoWunschSvg from "../assets/images/dist/logo-wunsch.svg";
import logoWunschMiniSvg from "../assets/images/dist/logo-wunsch-mini.svg";
import logoPortalSvg from "../assets/images/dist/logo-portal.svg";
import logoPortalMiniSvg from "../assets/images/dist/logo-portal-mini.svg";
// import { setFormStep } from "redux/actions/setFormStage";

export const Header: React.FC = () => {
//  const dispatch = useDispatch(); // TODO: Check if it can  be removed safely.
    const [activeMobMenu, setActiveMobMenu] = React.useState<boolean>(false);

    const handleMobMenu = (): void => {
        setActiveMobMenu(!activeMobMenu);
    };

    const handleCalculatorScroll = () => {
        sendMetrics("Going to the calculator", "MortageForm", "RechnerButtonPressed");
        let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
        window.scrollTo(0, scroll_to);
    }

    return (
        <header>
            <nav className='main-navbar'>
                <Link to="/" className='main-navbar__logo'>
                    <img src={logoSvg} alt='logo icon' className='logo__large'/>
                    <img src={logoMiniSvg} alt='logo mini icon' className='logo__small'/>
                </Link>
                <a href="https://eigenheimwunsch.de/" className='main-navbar__logo main-navbar__logo-wunsch'>
                    <img src={logoWunschSvg} alt='logo icon' className='logo__large'/>
                    <img src={logoWunschMiniSvg} alt='logo mini icon' className='logo__small'/>
                </a>
                <a href="http://eigenheimportal.de/" className='main-navbar__logo main-navbar__logo-portal'>
                    <img src={logoPortalSvg} alt='logo icon' className='logo__large'/>
                    <img src={logoPortalMiniSvg} alt='logo mini icon' className='logo__small'/>
                </a>
                <div
                    className={
                        activeMobMenu
                            ? "main-navbar__toggle expanded"
                            : "main-navbar__toggle"
                    }
                    onClick={handleMobMenu}
                >
                    <div className='main-navbar__toggle__bar'></div>
                </div>
                <ul
                    className={
                        activeMobMenu
                            ? "main-navbar__menu main-navbar__collapse expanded"
                            : "main-navbar__menu main-navbar__collapse"
                    }
                >
                    <li>
                        <Link to='/'>Home</Link>
                    </li>
                    <li>
                        <Link to="/partner">Partner werden</Link>
                    </li>
                    <li>
                        <Link to="/contact">Kontakt</Link>
                    </li>
                    <li className='main-navbar__menu__calc'>
                        <Link to="/" onClick={handleCalculatorScroll}>Zum Rechner</Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};
