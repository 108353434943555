import React from "react";
import { useDispatch } from "react-redux";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { setFormError } from "redux/actions/setFormError";

interface IProps {
  submitPayment: () => void;
}

export const StripeForm = React.forwardRef<IProps, {}>((props, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
			confirmParams: {
        return_url: window.location.href,
      },
    });

    if (result.error) {
      showError(result.error.message);
    } else if (result) {
      return result;
    }
  };

  const showError = (text?: string) => {
    const errorObj = {
      isError: true,
      text: text
    };

    dispatch(setFormError(errorObj));
  };

  React.useImperativeHandle(
    ref,
    () => ({
      async submitPayment() {
        return await onSubmit();
      }
    })
  )

  return (
    <PaymentElement />
  );
});
