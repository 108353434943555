import React from "react";
import { useFela } from "react-fela";
import { useSelector } from "react-redux";
import homeIcon from "../../../assets/images/dist/rechner/final-step-home-in-hands-icon.png";

import { finalInfoContainer } from "./styles";
import { firstStepForm, dnone, dblock } from "../styles";

interface Iprops {
  hidden: boolean;
}

export const FinalStep = React.memo((props: Iprops) => {
  const { css } = useFela();

  const { purchaseNumber } = useSelector(
    (purchaseNumberObj) => purchaseNumberObj.purchaseNumberReducer
  );
  const {
    Anrede,
    Zahlungsart,
    Name,
    Surname,
    Strasse,
    Hausnummer,
    paymentPlz,
    Stadt,
    Land
  } = useSelector(
    (payerDataObj) => payerDataObj.payerDataReducer
  );

  return (
    <form
      className={css(firstStepForm, props.hidden ? dnone : dblock)}
      id='formHeading'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className={`${css(finalInfoContainer)}`}>
              <div className="image-wrapper">
                <img src={homeIcon} alt="Magnifier icon" className="magnifier-image" />
              </div>
              <div className='heading-wrapper'>
                <h2 className="h2">
                  VIELEN DANK FÜR IHRE BESTELLUNG
                </h2>
              </div>
              <div className="text-wrapper">
                <p>
									Lieber Kunde,
                </p>
                <p>
                  vielen Dank für Ihre Bestellung bei EigenheimInfo.de <br/>
                  In Kürze erhalten Sie Ihr Wertermittlungszertifikat von uns per E-Mail. <br/>
                  Wir wünschen Ihnen viel Erfolg mit Ihrem Zertifikat! <br/>
                </p>
                <p>
                  Mit freundlichen Grüßen <br/>
                  <span>Ihr EigenheimInfo.de</span>
                </p>
              </div>
              <div className="info-wrapper">
                <div className="info-heading">
                  BESTELLINFORMATIONEN
                </div>
                <div className="info-text-wrapper">
                  <div className="info-cell">
                    <strong>Bestellnummer</strong>
                    <span>{purchaseNumber}</span>
                  </div>
                  <div className="info-cell">
                    <strong>Zahlungsart</strong>
                    <span>{Zahlungsart}</span>
                  </div>
                  <div className="info-cell">
                    <strong>Rechnungsadresse</strong>
                    <span>{Anrede}</span>
                    <span>{Name} {Surname}</span>
                    <span>{Strasse}{Hausnummer ? `, ${Hausnummer}` : null}</span>
                    <span>{paymentPlz} {Stadt}</span>
                    <span>{Land}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});
