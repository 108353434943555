import { FelaStyle } from "react-fela";

export const radioButtonGroup: FelaStyle<{}> = () => ({
  marginTop: "3em",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  "@media screen and (max-width: 768px)": {
    flexDirection: "column",
    alignItems: "center"
  },
  "> div": {
    "@media screen and (max-width: 768px)": {
      padding: ".1em",
    },
    "> input": {
      display: "none",
      ":checked + label": {
        backgroundColor: "#fff",
      },
    },
    "> label": {
      "> img": {
        width: "13em",
        "@media (max-width: 992px) and (min-width: 767px)": {
            width: "unset"
        },
        "@media (max-width: 576px)": {
          "> img": {
            maxHeight: "10em",
            maxWidth: "8em",
          },
        },
      },
    },
  },
});

export const eigentumswohnung: FelaStyle<{}> = () => ({
  height: "26em",
  "@media screen and (max-width: 768px)": {
    height: "21em",
    maxWidth: "26em",
    paddingTop: "4em",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  "> div": {
    maxHeight: "22em",
    "> label": {
      "> img": {
        width: "unset",
      },
    },
  },
  "> .etagenwohnung": {
    display: "flex",
    flexFlow: "column nowrap"
  }
});

export const selectContainer: FelaStyle<{}> = () => ({
  maxWidth: "80%",
  width: "unset",
  marginTop: "1em",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  "@media screen and (max-width: 576px)": {
    maxWidth: "unset",
    width: "150%",
  }
});

export const customSelect: FelaStyle<{}> = () => ({
  "&.has-error": {
    backgroundColor: "#fff6f6",
    border: "1px solid #df6969",
    borderRadius: "8px"
  },
  "> .customSelect__control": {
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%",
    height: "2em",
    borderRadius: "9px",
    "&.customSelect__control--menu-is-open": {
      border: "1px solid #97bbb8",
      borderBottom: "none",  
    }
  },
  "> .customSelect__menu": {
    height: "4.5em",
    width: "100% !important",
    boxShadow: "none !important",
    border: "1px solid #97bbb8",
    borderTop: "none",
    borderRadius: "0 0 9px 9px",
    "> .customSelect__menu-list": {
      fontSize: "14px",
      lineHeight: "1.5em",
      height: "inherit",
    }
  },
});

export const floorSelect: FelaStyle<{}> = () => ({
  marginLeft: "14%",
  "@media screen and (max-width: 767px)": {
    marginLeft: "unset !important",
  },
  "@media screen and (max-width: 991px)": {
    marginLeft: "7%",
  },
});
