import axios from "axios";

interface IRequestData {
  bundesland: string,
  plz: number
}

const getBodenwert = async (requestData: IRequestData): Promise<boolean> => {
  const headers = {
    "Content-Type": "application/json",
  };
  const data = requestData;
  const url = "https://api.eigenheiminfo.de/api_central/v1/bodenwert/get";
  return axios({
    method: "POST",
    url,
    data,
    headers,
  })
  .then((response) => {
    return response.status === 204; // 204 No Content means data was found.
  })
  .catch((error) => {
    console.log("No data found in the database.");
    return false
  });
};

export default getBodenwert;
