import { FelaStyle } from "react-fela";

export const selectContainer: FelaStyle<{}> = () => ({
  height: "3.25em",
  width: "unset",
  marginBottom: "1em",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
});

export const input: FelaStyle<{}> = () => ({
  marginTop: "0 !important",
});

export const customSelect: FelaStyle<{}> = () => ({
  "&.has-error": {
    backgroundColor: "#fff6f6",
    border: "1px solid #df6969",
    borderRadius: "8px"
  },
  "> .customSelect__control": {
    fontSize: "14px",
    height: "3.25em",
    lineHeight: "21px",
    borderRadius: "9px",
    "&.customSelect__control--menu-is-open": {
      border: "1px solid #97bbb8",
      borderBottom: "none",  
    }
  },
  "> .customSelect__menu": {
    height: "5em",
    width: "14em !important",
    boxShadow: "none !important",
    border: "1px solid #97bbb8",
    borderTop: "none",
    borderRadius: "0 0 9px 9px",
    "> .customSelect__menu-list": {
      fontSize: "14px",
      lineHeight: "1.5em",
      height: "inherit",
    }
  },
});

export const cardRow: FelaStyle<{}> = () => ({
  flexFlow: "row nowrap",

	"@media (max-width: 992px)": {
    flexFlow: "row wrap"
  },
});
