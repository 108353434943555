import React from "react";
import { FirstStep } from "./FirstStep";
import { useSelector } from "react-redux";
import { SecondStep } from "./SecondStep";
import { ThirdStep } from "./ThirdStep";
import { FourthStep } from "./FourthStep";
import { FifthStep } from "./FifthStep";
import { SixthStep } from "./SixthStep";
import { SeventhStep } from "./SeventhStep";
import { EighthStep } from "./EighthStep";
import { NinthStep } from "./NinthStep";
import { TenthStep } from "./TenthStep";
import { ToCheckout } from "./ToCheckout";
import { Checkout } from "./Checkout";
import { StripeStep } from "./StripeStep";
import { FinalStep } from "./FinalStep";
import { ErrorModal } from "../ErrorModal";
import { setFormError } from "../../redux/actions/setFormError";
import { useDispatch } from "react-redux";
import { setFormStep } from "redux/actions/setFormStage";
import { setCalculatorData } from "redux/actions/setCalculatorData";
import { setPayerData, setPaymentData } from "redux/actions/setPayerData";
import { setPurchaseNumber } from "redux/actions/setPurchaseNumber";

export const MortageForm: React.FC = () => {
	const dispatch = useDispatch();

  const { activeStep, initFormData } = useSelector(
    (formStageObj) => formStageObj.formStageReducer
  );

	const [loading, setLoading] = React.useState<boolean>(false);
	const [queryStatus, setQueryStatus] = React.useState<boolean>(false);

	const handleQueryString = () => {
		const queryString = window.location.search;
		const sp = new URLSearchParams(queryString);

		if (sp.has('redirect_status')) {
			const formData = getFromLocalStorage('form');
			const paymentMethod = getFromLocalStorage('paymentMethod');
			const purchaseNumber = getFromLocalStorage('purchaseNumber');
			if (!formData || !paymentMethod || !purchaseNumber) return;

			const payerData = {
				gender: formData.Anrede,
				vorname: formData.Name,
				nachname: formData.Surname,
				adresszeile: formData.AdresseRechnung,
				plz: formData.PLZRechnung,
				stadt: formData.OrtRechnung,
				land: formData.LandRechnung,
			};
			dispatch(setCalculatorData(formData));
			dispatch(setPayerData(payerData));
			dispatch(setPaymentData({
				zahlungsart: paymentMethod,
				promocode: null
			}));
			dispatch(setPurchaseNumber(purchaseNumber));

			if (sp.getAll('redirect_status').includes('succeeded')) {
				removeFromLocalStorage('form');
				removeFromLocalStorage('paymentMethod');
				removeFromLocalStorage('purchaseNumber');
				dispatch(setFormStep(14));
			} else if (sp.getAll('redirect_status').includes('failed')) {
				removeFromLocalStorage('form');
				removeFromLocalStorage('paymentMethod');
				removeFromLocalStorage('purchaseNumber');
				dispatch(setFormStep(12));
			}
			setLoading(false);
			setQueryStatus(true);
		}
	}

	const getFromLocalStorage = (key: string) => {
		const item = localStorage.getItem(key);
		if (item) {
			return JSON.parse(item);
		}
	}

	const removeFromLocalStorage = (key: string) => {
		return localStorage.removeItem(key);
	}

	React.useEffect(() => {
		setLoading(initFormData);
		handleQueryString();
	}, [initFormData]);

	React.useEffect(() => {
		handleQueryString();
	}, []);

  return (
    <>
      <FirstStep hidden={activeStep !== 1} />
			{
				!loading || queryStatus ?
				<>
					<SecondStep hidden={activeStep !== 2} />
					<ThirdStep hidden={activeStep !== 3} />
					<FourthStep hidden={activeStep !== 4} />
					<FifthStep hidden={activeStep !== 5} />
					<SixthStep hidden={activeStep !== 6} />
					<SeventhStep hidden={activeStep !== 7} />
					<EighthStep hidden={activeStep !== 8} />
					<NinthStep hidden={activeStep !== 9} />
					<TenthStep hidden={activeStep !== 10} />
					<ToCheckout hidden={activeStep !== 11} />
					<Checkout hidden={activeStep !== 12} />
					<StripeStep hidden={activeStep !== 13} />
					<FinalStep hidden={activeStep !== 14} />
				</> : null
			}
      <ErrorModal />
    </>
  );
};

export const setError = (dispatch: any, textOfError: string) => {
  const errObj = {
    isError: true,
    text: textOfError,
  };

  dispatch(setFormError(errObj));
};
