import React from "react";
import { useFela } from "react-fela";
import { Range, getTrackBackground } from "react-range";
import {
  rangeInputContainer,
  rangeInputLabels
} from "./styles";

interface Iprops {
  options: { value: number, label: string}[];
  step?: number;
  descriptionText?: string;
  onChange: (arg0: number) => void
}

export const RangeSlider = React.memo((props: Iprops) => {
  const { css } = useFela();

  const rangeInputOptions = props.options;
  const rangeInputStep = props.step || 1;

  const [rangeValues, setRangeValues] = React.useState<number[]>([0])

  const handleRangeInput = (values: number[]) => {
    setRangeValues(values);
    props.onChange(rangeInputOptions[values[0]].value);
  };

  const renderRangeInputLabels = () => {
    const labels: JSX.Element[] = [];
    rangeInputOptions.forEach((option, i) => labels.push(<span key={i}>{option.label}</span>));
    return labels;
  };

  return (
    <div className={css(rangeInputContainer)}>
      <div className={`${css(rangeInputLabels)} labels`}>
        {renderRangeInputLabels()}
      </div>
      <Range
        values={rangeValues}
        step={rangeInputStep}
        min={0}
        max={rangeInputOptions.length-1}
        onChange={handleRangeInput}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: rangeValues,
                  colors: ["#548BF4", "#ccc"],
                  min: 0,
                  max: 20000,
                }),
                alignSelf: "center",
              }}
              className="track-background"
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              height: "26px",
              width: "26px",
              borderRadius: "50%",
              backgroundColor: "#007772",
              display: "flex",
              outline: "none",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          ></div>
        )}
      />
      {
        props.descriptionText ?
        <span className="range-input-text">
          {props.descriptionText}
        </span> : ""
      }
    </div>
  );
});
