import { FelaStyle } from "react-fela";

export const houseDetailsContainer: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  "@media screen and (max-width: 767px)": {
    padding: "0 1.5em",
    flexFlow: "column nowrap",
  },
  "@media screen and (min-width: 767px)": {
    minHeight: "20em",
  },
});

export const partSelectionContainer: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "center",
  flexBasis: "60%",
  "@media screen and (max-width: 767px)": {
    marginBottom: "4em",
    position: "relative",
    flexFlow: "column nowrap",
    flexBasis: "auto",
    "> .image-wrapper": {
      marginTop: "4.5em",
      width: "100%",
      justifyContent: "center",
      "> img": {
        width: "100%",
      },
    },
    "> .select-wrapper": {
      width: "100%",
      "> div.dach": {
        width: "100%",
        position: "absolute",
        top: "0"
      },
    },
  },
  "@media screen and (min-width: 768px) and (max-width: 991px)": {
    "> .select-wrapper": {
      paddingLeft: "1em",
    },
  },
  "@media screen and (min-width: 768px)": {
    "&.selected > .select-wrapper": {
      justifyContent: "space-around",
      marginBottom: "unset",
    },
  },
  "> .image-wrapper": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexBasis: "100%",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    "> img": {
      cursor: "inherit",
    },
    "&:disabled": {
      cursor: "default",
    }
  },
  "> .select-wrapper": {
    height: "70%",
    marginBottom: "3.75em",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-end",
    flexBasis: "70%",
  },
});

export const rightSection: FelaStyle<{}> = () => ({
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  "@media screen and (max-width: 767px)": {
    marginBottom: "1em",
  },
});

export const customSelect: FelaStyle<{}> = () => ({
  marginTop: "1.5em",
  "&.has-error": {
    backgroundColor: "#fff6f6",
    border: "1px solid #df6969",
    borderRadius: "8px"
  },
  "> .customSelect__control": {
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%",
    height: "2.5em",
  },
  "> .customSelect__menu": {
    width: "100% !important",
  },
});
