import React from "react";
import Select from 'react-select';
import {useFela} from "react-fela";
import {useDispatch} from "react-redux";
import {setFormStep} from "redux/actions/setFormStage";
import {setPayerData} from "redux/actions/setPayerData";
import {sendMetrics} from "../../../utils/google-analytics";

import {Stepper} from "../Stepper";
import userIcon from "../../../assets/images/dist/rechner/to-checkout-user-icon.png";
import addressIcon from "../../../assets/images/dist/rechner/to-checkout-address-icon.png";

import {
    formSubheading,
    sectionContainer,
    imageWrapper,
    inputsWrapper,
    selectContainer,
    inputsContainer,
    customSelect,
} from "./styles";
import {
    firstStepForm,
    formHeading,
    formButton,
    customInput,
    dnone,
    dblock,
} from "../styles";

interface Iprops {
    hidden: boolean;
}

interface FormData {
    vorname: string | null;
    nachname: string | null;
    email: string | null;
    telefonnummer: string | null;
    firma: string | null;
    adresszeile: string | null;
    plz: string | undefined;
    stadt: string | null;
    land: string | null;
}

interface FormValidation {
    vorname: boolean;
    nachname: boolean;
    email: boolean;
    telefonnummer: boolean;
    adresszeile: boolean;
    plz: boolean;
    stadt: boolean;
    land: boolean;
}

interface ISelectOptions {
    value: number;
    label: string;
}

export const ToCheckout = React.memo((props: Iprops) => {
    const {css} = useFela();
    const dispatch = useDispatch();

//  const { activeStep } = useSelector(
//    (formStageObj) => formStageObj.formStageReducer
//  );

    const selectOptions = [
        {value: 0, label: 'Herr'},
        {value: 1, label: 'Frau'},
    ];
    const initialFormValidation = {
        vorname: false,
        nachname: false,
        email: false,
        telefonnummer: false,
        adresszeile: false,
        plz: false,
        stadt: false,
        land: false
    };

    const [formData, setFormData] = React.useState<FormData>({
        vorname: null,
        nachname: null,
        email: null,
        telefonnummer: null,
        firma: '',
        adresszeile: null,
        plz: '',
        stadt: null,
        land: null
    });
    const [gender, setGender] = React.useState<ISelectOptions>(selectOptions[0]);
    const [formValidation, setFormValidation] = React.useState<FormValidation>(initialFormValidation);

    const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value: string | number = e.target.value;
        if (e.target.name === 'plz') {
            if (value.length > 5) {
                value = value.slice(0, 5);
            }
        } else {
            value = e.target.value;
        }
        setFormData({
            ...formData,
            [e.target.id]: value
        });
        clearValidation();
    };

    const handleGenderSelect = (obj: ISelectOptions) => {
        setGender(obj);
    };

    const validateInputs = () => {
        function validateEmail(email: string) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        let hasErrors = false;
        const validation = {...formValidation};
        Object.keys(formValidation).forEach((fieldName, i) => {
            if (!formData[fieldName] || (formData[fieldName] && formData[fieldName].length < 1) ||
                (fieldName === 'email' && formData.email && !validateEmail(formData.email))) {
                validation[fieldName] = true;
                hasErrors = true;
            }
        });

        setFormValidation(validation);
        return hasErrors;
    };

    const clearValidation = () => {
        setFormValidation(initialFormValidation);
    };

    const scrollToForm = () => {
        let scroll_to = (document.getElementById("CalculatorForm") as HTMLElement).offsetTop;
        window.scrollTo(0, scroll_to);
    };

    const handleBackBtn = () => {
        sendMetrics("BackToTheCalculator", "MortageForm", "BackButtonPressed");
        dispatch(setFormStep(10));
        scrollToForm();
    };

    const handleContinueBtn = () => {
        const hasErrors = validateInputs();
        if (hasErrors) return;

        const payerData = {
            ...formData,
            gender: gender.label
        };
        sendMetrics("CustomerDataSubmitted", "MortageForm", "NextButtonPressed");
        dispatch(setPayerData(payerData));
        dispatch(setFormStep(12));
        scrollToForm();
    };

    return (
        <form
            className={css(firstStepForm, props.hidden ? dnone : dblock)}
            id='formHeading'
        >
            <div className='container'>
                <Stepper/>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className={`h2 ${css(formHeading)}`}>Zur Kasse</h2>
                        <span className={css(formSubheading)}>
              Bitte geben Sie Ihre Personen- und Rechnungsdaten ein
            </span>
                    </div>
                    <div className='col-12'>
                        <div className={css(sectionContainer)}>
                            <div className={css(imageWrapper)}>
                                <img src={userIcon} alt="User icon" className="section-icon"/>
                                <span className="section-icon-label">
                  Persönliche Angaben
                </span>
                            </div>
                            <div className={css(inputsWrapper)}>
                                <div className={css(selectContainer)}>
                                    <Select
                                        classNamePrefix='customSelect'
                                        options={selectOptions}
                                        value={gender}
                                        placeholder=""
                                        className={css(customSelect)}
                                        onChange={handleGenderSelect}/>
                                </div>
                                <div className={css(inputsContainer)}>
                                    <input
                                        name='vorname'
                                        type='text'
                                        id='vorname'
                                        placeholder="Vorname"
                                        className={`${css(customInput)} ${formValidation.vorname ? 'has-error' : ''}`}
                                        onChange={handleInput}
                                    />
                                    <input
                                        name='nachname'
                                        type='text'
                                        id='nachname'
                                        placeholder="Nachname"
                                        className={`${css(customInput)} ${formValidation.nachname ? 'has-error' : ''}`}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className={css(inputsContainer)}>
                                    <input
                                        name='email'
                                        type='text'
                                        id='email'
                                        placeholder="E-Mail"
                                        className={`${css(customInput)} ${formValidation.email ? 'has-error' : ''}`}
                                        onChange={handleInput}
                                    />
                                    <input
                                        name='telefonnummer'
                                        type='text'
                                        id='telefonnummer'
                                        placeholder="Telefonnummer"
                                        className={`${css(customInput)} ${formValidation.telefonnummer ? 'has-error' : ''}`}
                                        onChange={handleInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={css(sectionContainer)}>
                            <div className={css(imageWrapper)}>
                                <img src={addressIcon} alt="Building icon" className="section-icon"/>
                                <span className="section-icon-label">
                  Rechnungsadresse
                </span>
                            </div>
                            <div className={css(inputsWrapper)}>
                                <div className={css(inputsContainer)}>
                                    <input
                                        name='firma'
                                        type='text'
                                        id='firma'
                                        placeholder="Firma (optional)"
                                        className={css(customInput)}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className={css(inputsContainer)}>
                                    <input
                                        name='adresszeile'
                                        type='text'
                                        id='adresszeile'
                                        placeholder="Adresszeile"
                                        className={`${css(customInput)} ${formValidation.adresszeile ? 'has-error' : ''}`}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className={css(inputsContainer)}>
                                    <input
                                        name='plz'
                                        type='number'
                                        min={0}
                                        value={formData.plz}
                                        id='plz'
                                        placeholder="PLZ"
                                        className={`${css(customInput)} ${formValidation.plz ? 'has-error' : ''}`}
                                        onChange={handleInput}
                                    />
                                    <input
                                        name='stadt'
                                        type='text'
                                        id='stadt'
                                        placeholder="Stadt"
                                        className={`${css(customInput)} ${formValidation.stadt ? 'has-error' : ''}`}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className={css(inputsContainer)}>
                                    <input
                                        name='land'
                                        type='text'
                                        id='land'
                                        placeholder="Land"
                                        className={`${css(customInput)} ${formValidation.land ? 'has-error' : ''}`}
                                        onChange={handleInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={css(formButton)}>
                            <button
                                type='button'
                                className='button empty'
                                onClick={handleBackBtn}
                            >
                                Zurück
                            </button>
                            <button
                                type='button'
                                className='button'
                                onClick={handleContinueBtn}
                            >
                                <span>Weiter</span>
                                <span className="gt">&gt;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
});
